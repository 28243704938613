import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LocalStorageModule } from 'angular-2-local-storage';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AccessDeniedComponent } from '../errors/access-denied.component';
import { AuthorizationInterceptor } from './auth/authorization.interceptor';
import { CallbackComponent } from './auth/callback.component';
import { HasScopeDirective } from './auth/has-scope.directive';
import { HasValidAccountDirective } from './auth/has-valid-account.directive';
import { IdentityComponent } from './auth/identity.component';
import { LoginComponent } from './auth/login.component';
import { LogoutComponent } from './auth/logout.component';
import { ProfileComponent } from './auth/profile.component';
import { SignupComponent } from './auth/signup.component';
import { BusyIndicatorComponent } from './busy-indicator.component';
import { ButtonDateRangeComponent } from './button-date-range.component';
import { CopyToClipboardComponent } from './copy-to-clipboard.component';
import { DateRangeTextBoxComponent } from './date-range-text-box.component';
import { FocusDirective } from './directives/focus.directive';
import { GoodBadClassDirective } from './directives/good-bad-class';
import { NgModelChangeDebouncedDirective } from './directives/ng-model-change-debounced.directive';
import { ToggleRevealDirective } from './directives/toggle-reveal.directive';
import { DollarsWithCentsTooltipComponent } from './dollars-with-cents-tooltip.component';
import { FirstCellComponent } from './first-cell.component';
import { HelpBladeComponent } from './help-blade.component';
import { DateInterceptor } from './interceptors/date.interceptor';
import { RateLimitingClientInterceptor } from './interceptors/rate-limiting-client.interceptor';
import { BladeTabsComponent } from './layout/blade-tabs.component';
import { BladeComponent } from './layout/blade.component';
import { ButtonTabsComponent } from './layout/button-tabs.component';
import { ContentHeaderComponent } from './layout/content-header.component';
import { DataFormComponent } from './layout/data-form.component';
import { DataTableComponent } from './layout/data-table.component';
import { HeaderComponent } from './layout/header.component';
import { HistoryBladeComponent } from './layout/history-blade.component';
import { LeftnavComponent } from './layout/leftnav.component';
import { OutletComponent } from './layout/outlet.component';
import { PageHeaderComponent } from './layout/page-header.component';
import { PageSecondaryTabsComponent } from './layout/page-secondary-tabs.component';
import { PageTabsComponent } from './layout/page-tabs.component';
import { PageThirdLevelTabsComponent } from './layout/page-third-level-tabs.component';
import { PageTopFiltersComponent } from './layout/page-top-filters.component';
import { LoadingIndicatorComponent } from './loading-indicator.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { MetricCurrentValueComponent } from './metric-current-value.component';
import { MultiSelectComponent } from './multi-select.component';
import { PercentWithTooltipComponent } from './percent-with-tooltip.component';
import { PipesModule } from './pipes/pipes.module';
import { ShortCurrencyWithTooltipComponent } from './short-currency-with-tooltip.component';
import { ShortNumberWithTooltipComponent } from './short-number-with-tooltip.component';
import { UnconfiguredNetworksComponent } from './unconfigured-networks.component';
import { FormValidationMessagesComponent } from './validation/form-validation-messages.component';
import { WorkInProgressComponent } from './work-in-progress.component';

@NgModule({
  declarations: [
    AccessDeniedComponent,
    BladeComponent,
    BladeTabsComponent,
    BusyIndicatorComponent,
    ButtonDateRangeComponent,
    ButtonTabsComponent,
    CallbackComponent,
    ContentHeaderComponent,
    ContentHeaderComponent,
    CopyToClipboardComponent,
    DataFormComponent,
    DataTableComponent,
    DateRangeTextBoxComponent,
    DollarsWithCentsTooltipComponent,
    FirstCellComponent,
    FormValidationMessagesComponent,
    HeaderComponent,
    HelpBladeComponent,
    HistoryBladeComponent,
    IdentityComponent,
    LeftnavComponent,
    LoadingIndicatorComponent,
    LoginComponent,
    LogoutComponent,
    MessageBoxComponent,
    MultiSelectComponent,
    OutletComponent,
    PageHeaderComponent,
    PageSecondaryTabsComponent,
    PageTabsComponent,
    PageThirdLevelTabsComponent,
    PageTopFiltersComponent,
    PercentWithTooltipComponent,
    ProfileComponent,
    ShortCurrencyWithTooltipComponent,
    ShortNumberWithTooltipComponent,
    SignupComponent,
    WorkInProgressComponent,
    FocusDirective,
    GoodBadClassDirective,
    HasScopeDirective,
    HasValidAccountDirective,
    NgModelChangeDebouncedDirective,
    ToggleRevealDirective,
    UnconfiguredNetworksComponent,
    MetricCurrentValueComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LocalStorageModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    FormsModule,
    PipesModule,

    BsDropdownModule,
    BsDatepickerModule,
    PopoverModule,
    TooltipModule,
    NgSelectModule,
    UiSwitchModule,
    ProgressbarModule,
    ReactiveFormsModule,
    NgOptimizedImage
  ],
  exports: [
    AccessDeniedComponent,
    BladeComponent,
    BladeTabsComponent,
    BusyIndicatorComponent,
    ButtonDateRangeComponent,
    ButtonTabsComponent,
    CallbackComponent,
    ContentHeaderComponent,
    ContentHeaderComponent,
    CopyToClipboardComponent,
    DataFormComponent,
    DataTableComponent,
    DateRangeTextBoxComponent,
    DollarsWithCentsTooltipComponent,
    FirstCellComponent,
    FormValidationMessagesComponent,
    HeaderComponent,
    HelpBladeComponent,
    HistoryBladeComponent,
    IdentityComponent,
    LeftnavComponent,
    LoadingIndicatorComponent,
    LoginComponent,
    LogoutComponent,
    MessageBoxComponent,
    MultiSelectComponent,
    OutletComponent,
    PageHeaderComponent,
    PageSecondaryTabsComponent,
    PageTabsComponent,
    PageThirdLevelTabsComponent,
    PageTopFiltersComponent,
    PercentWithTooltipComponent,
    ProfileComponent,
    ShortCurrencyWithTooltipComponent,
    ShortNumberWithTooltipComponent,
    SignupComponent,
    WorkInProgressComponent,
    FocusDirective,
    GoodBadClassDirective,
    HasScopeDirective,
    HasValidAccountDirective,
    NgModelChangeDebouncedDirective,
    ToggleRevealDirective,
    UnconfiguredNetworksComponent,
    MetricCurrentValueComponent
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ToggleRevealDirective,
        GoodBadClassDirective,
        NgModelChangeDebouncedDirective,
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true }, // Converts date strings to dates
        { provide: HTTP_INTERCEPTORS, useClass: RateLimitingClientInterceptor, multi: true }, // Adds X-ClientId header for rate limiter
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true } // Handles 401
      ]
    };
  }
}
