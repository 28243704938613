import { PercentPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash-es';

@Injectable()
@Pipe({ name: 'percentageChange' })
export class PercentageChangePipe implements PipeTransform {

  public constructor(private readonly percentPipe: PercentPipe) {}

  public transform(value: number, digitsInfo = '1.0-1', negativeIsGood = false, neutral = false): string {
    const roundedValue = round(value * 100);
    if (!roundedValue) { return ''; }
    const positiveClass = neutral ? '' : negativeIsGood ? 'bad ' : 'good ';
    const negativeClass = neutral ? '' : negativeIsGood ? 'good ' : 'bad ';
    return `<em class="${roundedValue > 0 ? positiveClass : value < 0 ? negativeClass : ''}percentage-change">${roundedValue > 0 ? '<i class="fa-solid fa-caret-up"></i> +' : '<i class="fa-solid fa-caret-down"></i> '}${this.percentPipe.transform(value, digitsInfo)}</em>`;
  }

}
