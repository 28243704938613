import { Injectable } from '@angular/core';
import { cloneDeep, isEqual, mapKeys } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { Cadence } from '../../api-model/enums/cadence';
import { AuthService } from '../../shared/auth/auth.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { SessionStorageService } from '../../shared/services/session-storage.service';
import { AnalysisSettings } from './analysis-settings';
import { IAnalysisSettingsLocalStorage } from './analysis-settings-local-storage';
import { IAnalysisSettingsSessionStorage } from './analysis-settings-session-storage';
import { DateRange } from 'src/app/shared/date-range';

@Injectable({
  providedIn: 'root'
})
export class AnalysisSettingsService {

  private readonly settings = new AnalysisSettings();
  private readonly storageKey = 'analysis.settings';

  public constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly localStorageService: LocalStorageService,
    auth: AuthService
  ) {
    // Load existing settings from session and local storage
    const sessionSettings = sessionStorageService.get<IAnalysisSettingsSessionStorage>(this.storageKey) || {} as IAnalysisSettingsSessionStorage;
    if (sessionSettings) {
      if (sessionSettings.regionId) { this.settings.regionId = sessionSettings.regionId; }
      if (sessionSettings.networkId) { this.settings.networkId = sessionSettings.networkId; }
    }

    const localSettings = localStorageService.get<IAnalysisSettingsLocalStorage>(this.storageKey) || {} as IAnalysisSettingsLocalStorage;
    if (localSettings) {
      if (localSettings.cadence) { this.settings.cadence = Cadence[localSettings.cadence]; }
      this.settings.cadence ??= Cadence.Weekly;

      if (localSettings.dateRange) { this.settings.dateRange = localSettings.dateRange }
      this.settings.dateRange ??= DateRange.toDateRangeDto(DateRange.default);

      if (localSettings.isCustomDateRange) { this.settings.isCustomDateRange = localSettings.isCustomDateRange }
      this.settings.isCustomDateRange ??= false;

      localSettings.smoothingLevel ??= {};
      this.settings.setSmoothingLevel(Cadence.Daily, localSettings.smoothingLevel[Cadence[Cadence.Daily]] ?? 1);
      this.settings.setSmoothingLevel(Cadence.Weekly, localSettings.smoothingLevel[Cadence[Cadence.Weekly]] ?? 0);
      this.settings.setSmoothingLevel(Cadence.Monthly, localSettings.smoothingLevel[Cadence[Cadence.Monthly]] ?? 0);
    }

    this._settings$ = new BehaviorSubject<AnalysisSettings>(cloneDeep(this.settings));

    // Reset settings whenever the user's active advertiser changes
    auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => await this.reset(true));
  }

  private _settings$: BehaviorSubject<AnalysisSettings>;

  public get settings$() { return this._settings$.asObservable(); }

  public get regionId() { return this.settings.regionId; }

  public set regionId(value: string) {
    this.settings.regionId = value;
    this.update();
  }

  public get networkId() { return this.settings.networkId; }

  public set networkId(value: string) {
    this.settings.networkId = value;
    this.update();
  }

  public get cadence() { return this.settings.cadence; }

  public set cadence(value: Cadence) {
    this.settings.cadence = value;
    this.update();
  }

  public get smoothingLevel() { return this.settings.smoothingLevel; }

  public set smoothingLevel(value: number) {
    this.settings.setSmoothingLevel(this.cadence, value);
    this.update();
  }

  public get smoothingPeriod() { return this.settings.smoothingPeriod; }

  public get dateRange(): DateRange {
    if (this.settings.dateRange) return DateRange.fromDateRangeDto(this.settings.dateRange);
    return DateRange.getDefaultDateRange(this.settings.cadence);
  }

  public set dateRange(value: DateRange) {
    if (value === this.dateRange) { return; }
    this.settings.dateRange = DateRange.toDateRangeDto(value);
    this.update();
  }

  public get isCustomDateRange(): Boolean {
    return this.settings.isCustomDateRange;
  }

  public set isCustomDateRange(value: Boolean) {
    if (value === this.isCustomDateRange) { return; }
    this.settings.isCustomDateRange = value;
    this.update(true);
  }

  public async reset(suppressObservableUpdate = false) {
    // Only delete settings that are specific to the advertiser
    delete this.settings?.regionId;
    delete this.settings?.networkId;
    await this.update(suppressObservableUpdate);
  }

  public async update(suppressObservableUpdate = false) {
    this.sessionStorageService.set(this.storageKey, {
      regionId: this.settings.regionId,
      networkId: this.settings.networkId
    } as IAnalysisSettingsSessionStorage);

    this.localStorageService.set(this.storageKey, {
      cadence: Cadence[this.settings.cadence],
      smoothingLevel: mapKeys(this.settings.smoothingLevelByCadence, (_, i) => Cadence[i]),
      dateRange: this.settings.dateRange,
      isCustomDateRange: this.settings.isCustomDateRange
    } as IAnalysisSettingsLocalStorage);

    const b = cloneDeep(this.settings);
    b['rando'] = Math.random();

    if (!suppressObservableUpdate) { this._settings$.next(b); }
  }

}
