import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { mapValues } from 'lodash-es';
import { IAppOverviewMetricsDto } from '../../api-model/app-overview-metrics-dto';
import { DashboardPageSettingsService } from '../shared/dashboard-page-settings.service';
import { AppsAnalysisDataService } from './apps-analysis-data.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardAppsAnalysisResolver implements Resolve<IAppOverviewMetricsDto[]> {

  public constructor(
    private readonly appsAnalysisDataService: AppsAnalysisDataService,
    private readonly pageSettingsService: DashboardPageSettingsService
  ) { }

  public async resolve(): Promise<IAppOverviewMetricsDto[]> {
    return this.appsAnalysisDataService.get(mapValues(await this.pageSettingsService.get(), x => x.metrics));
  }

}
