import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';

@Directive({
  selector: '[ngModelChangeDebounced]'
})
export class NgModelChangeDebouncedDirective implements OnDestroy {

  @Output()
  public ngModelChangeDebounced = new EventEmitter<any>();

  @Input()
  public ngModelChangeDebounceTime = 500;

  subscription: Subscription;

  public constructor(private ngModel: NgModel) {
    this.subscription = this.ngModel.control.valueChanges.pipe(
      skip(1), // skip initial value
      distinctUntilChanged(),
      debounceTime(this.ngModelChangeDebounceTime)
    ).subscribe((value) => this.ngModelChangeDebounced.emit(value));
  }

  ngOnDestroy() { this.subscription.unsubscribe(); }
}
