import { Component, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-blade',
  templateUrl: './blade.component.html',
  styleUrls: ['./blade.component.scss']
})
export class BladeComponent {

  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public wide: boolean;
  @Input() public helpLink: string;

  public constructor(private readonly renderer: Renderer2) { }

  protected _isOpen = false;

  public get isOpen() {
    return this._isOpen;
  }

  public open() {
    this._isOpen = true;
    // Piggyback on styling provided by bootstrap to disable scrolling on the body whilst the blade is open
    if (document && document.body) {
      this.renderer.addClass(document.body, 'modal-open');
    }
  }

  public close() {
    this._isOpen = false;
    // Remove body scrolling disabled behaviour
    if (document && document.body) {
      this.renderer.removeClass(document.body, 'modal-open');
    }
  }

}
