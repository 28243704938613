import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEqual, isUndefined } from 'lodash-es';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IRegionDto } from '../api-model/region-dto';
import { SkipModelStateError } from '../errors/error.interceptor';
import { AudienceService } from '../shared/auth/audience.service';
import { AuthService } from '../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegionsDataService {

  private _regionsLoading?: Promise<IRegionDto[]>;

  public constructor(
    private readonly http: HttpClient,
    private readonly audience: AudienceService,
    auth: AuthService
  ) {
    // Update whenever the user's active advertiser changes
    auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(() => this.invalidate());
  }

  private _regions$ = new BehaviorSubject<IRegionDto[]>(undefined);

  public get regions$() {
    if (!this._regions$.value) { this.get(); }
    return this._regions$.asObservable();
  }

  private _updated$ = new Subject<void>();

  public get updated$() { return this._updated$.asObservable(); }

  public async get(ignoreCache = false): Promise<IRegionDto[]> {
    if (this._regionsLoading) { return this._regionsLoading; }
    if (!ignoreCache && !isUndefined(this._regions$.value)) { return this._regions$.value; }
    this._regionsLoading = new Promise(async resolve => {
      const data = (await firstValueFrom(this.http.get<IRegionDto[]>(`${environment.apiBase}regions`))) || [];
      this._regions$.next(data);
      resolve(data);
      this._regionsLoading = undefined;
    });
    return this._regionsLoading;
  }

  public async getById(id: string): Promise<IRegionDto | undefined> {
    return (await this.get()).find(x => x.id === id);
  }

  public async upsert(region: IRegionDto) {
    await firstValueFrom(this.http.put(`${environment.apiBase}regions`, region, {
      // Skip model state errors because we expect the consumer to handle them
      headers: new HttpHeaders().set(SkipModelStateError, '')
    }));
    this.invalidate();
  }

  public async delete(id: string) {
    await firstValueFrom(this.http.delete(`${environment.apiBase}regions/${id}`));
    this.invalidate();
  }

  public invalidate() {
    // If we have no subscribers, just invalidate the observable so that the next subscription will cause the data to be re-requested - otherwise re-request the data
    if (!this._regions$?.observed) { this._regions$?.next(undefined); } else { this.get(true); }
  }

}
