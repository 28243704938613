import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { isDate } from 'ngx-bootstrap/chronos';

@Injectable()
@Pipe({ name: 'shortDateRange' })
export class ShortDateRangePipe implements PipeTransform {

  public transform(start: string | Date | Moment, end: string | Date | Moment, alwaysShowYear = false, fullYear = false): string {
    if (start && (typeof start === 'string' || isDate(start))) { start = moment.utc(start); }
    if (end && (typeof end === 'string' || isDate(end))) { end = moment.utc(end); }
    if (!moment.isMoment(start) || !start.isValid() || start.year() <= 1900 || !moment.isMoment(end) || !end.isValid() || end.year() <= 1900) { return ''; }

    let startFormat = 'D';
    if (start.month() !== end.month()) { startFormat += ' MMM'; }
    if (start.year() !== end.year() && start.year() !== moment.utc().year()) { startFormat += fullYear ? ' YYYY' : ' YY'; }

    let endFormat = 'D MMM';
    if (end.year() !== moment.utc().year() || alwaysShowYear) { endFormat += fullYear ? ' YYYY' : ' YY'; }

    return `${start.format(startFormat)} - ${end.format(endFormat)}`;
  }

}
