import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardAppsAnalysisResolver } from './analysis/apps/dashboard-apps-analysis.resolver';
import { AppsResolver } from './apps/apps.resolver';
import { DashboardComponent } from './dashboard.component';
import { NetworksResolver } from './networks/networks.resolver';
import { RegionsResolver } from './regions/regions.resolver';
import { Permissions } from './shared/auth/permissions';
import { ScopeGuard } from './shared/auth/scope.guard';
import { ValidAccountGuard } from './shared/auth/valid-account.guard';
import { OutletComponent } from './shared/layout/outlet.component';

const routes: Routes = [
  {
    path: '',
    component: OutletComponent,
    children: [
      // Lazy-loaded feature modules
      {
        path: 'analysis',
        loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule),
        canLoad: [ValidAccountGuard]
      },
      {
        path: 'configuration',
        loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
        canLoad: [ValidAccountGuard, ScopeGuard],
        data: { requiredScopes: [Permissions.configuration] }
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canLoad: [ValidAccountGuard, ScopeGuard],
        data: { requiredScopes: [Permissions.admin] }
      },

      // The default (home) route
      {
        path: '',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [ValidAccountGuard],
        resolve: {
          apps: AppsResolver,
          analysis: DashboardAppsAnalysisResolver,
          networks: NetworksResolver,
          regions: RegionsResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: 'eager',
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
