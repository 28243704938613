import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { isDate } from 'ngx-bootstrap/chronos';

@Pipe({
  name: 'monthDateRange'
})
export class MonthDateRangePipe implements PipeTransform {

  public transform(value: string | Date | Moment): string {
    if (value && (typeof value === 'string' || isDate(value))) { value = moment.utc(value); }
    return moment.isMoment(value) && value.isValid() && value.year() >= 1900 ? value.format('MMM YY') : '';
  }

}
