import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SubscriptionTier } from 'src/app/api-model/enums/subscription-tier';

@Injectable()
@Pipe({ name: 'subscriptionTierName' })
export class SubscriptionTierNamePipe implements PipeTransform {

  public transform(value: SubscriptionTier, useBadge = true): string {
    switch (value) {
      case SubscriptionTier.Trial:
        return useBadge ? '<div class="icon-text-tag grey"><i class="fa-solid fa-balance-scale"></i> Trial</div>' : 'Trial';
      case SubscriptionTier.Standard:
        return useBadge ? '<div class="icon-text-tag green"><i class="fa-solid fa-certificate"></i> Standard</div>' : 'Standard';
      default:
        return '';
    }
  }

}
