import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IAppDto } from '../api-model/app-dto';
import { AppsDataService } from './apps-data.service';

@Injectable({
  providedIn: 'root'
})
export class AppsResolver implements Resolve<IAppDto[]> {

  public constructor(private readonly appsDataService: AppsDataService) {}

  // noinspection JSUnusedLocalSymbols
  public async resolve(): Promise<IAppDto[]> {
    return this.appsDataService.get();
  }

}
