<a (click)="show()"><i class="fa-solid fa-gear page-settings"></i></a>

<ng-template #pageSettings>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Page Settings</h4>
    <button (click)="modal.hide()" aria-label="Close" class="close float-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body no-padding">
    <div class="headline metrics picklist single-column">
      <h4>Metrics</h4>
      <ul>
        <li (click)="toggleMetric(metric.name)" *ngFor="let metric of availableMetrics" [class.selected]="form.value.metrics.includes(metric.name)">
          <i [class.fa-check-square]="form.value.metrics.includes(metric.name)" [class.fa-square]="!form.value.metrics.includes(metric.name)" class="fas"></i>
          <span class="name">{{metric.displayName}}</span>
          <span class="gap"></span>
          <a (click)="moveMetricToTop(metric.name, $event)" *ngIf="form.value.metrics.includes(metric.name)"><i class="fa-regular fa-circle-up"></i></a>
        </li>
      </ul>
      <app-form-validation-messages [model]="form.controls.metrics"></app-form-validation-messages>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-footer-content">
      <div class="left">
        <button (click)="submit()" [disabled]="!form.valid || saving" class="btn btn-primary">Save</button>
        <button (click)="modal.hide()" class="btn btn-secondary">Cancel</button>
      </div>
      <div class="right">
        <button (click)="resetByAppId()" class="btn btn-secondary">Reset to Default</button>
        <button (click)="resetAll()" class="btn btn-secondary">Default all Apps</button>
      </div>
    </div>
  </div>
</ng-template>
