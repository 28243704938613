import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss']
})
export class BusyIndicatorComponent {

  @Input() public style: 'line' | 'dots' | 'circle' | 'chase' = 'dots';

}
