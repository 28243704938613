import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEqual, isUndefined } from 'lodash-es';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IAttributionMetadataAppDto } from '../../api-model/attribution-metadata-app-dto';
import { AuthService } from '../../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppsAnalysisMetadataService {

  private loading?: Promise<IAttributionMetadataAppDto[]>;
  private cacheTimeout: number;

  public constructor(
    private readonly http: HttpClient,
    auth: AuthService
  ) {
    // Update whenever the user's active advertiser changes
    auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(() => this.invalidate());
  }

  private _metadata$ = new BehaviorSubject<IAttributionMetadataAppDto[]>(undefined);

  // noinspection JSUnusedGlobalSymbols
  public get metadata$() {
    if (!this._metadata$) { this._metadata$ = new BehaviorSubject<IAttributionMetadataAppDto[]>(undefined); }
    if (!this._metadata$.value) { this.get(); }
    return this._metadata$.asObservable();
  }

  public async get(ignoreCache = false): Promise<IAttributionMetadataAppDto[]> {
    if (this.loading) { return this.loading; }
    if (!this._metadata$) { this._metadata$ = new BehaviorSubject<IAttributionMetadataAppDto[]>(undefined); }
    if (!ignoreCache && !isUndefined(this._metadata$.value)) { return this._metadata$.value; }
    this.loading = new Promise(async resolve => {
      const result = await firstValueFrom(this.http.post<{
        data: {
          apps: IAttributionMetadataAppDto[]
        }
      }>(`${environment.attributionApiBase}graphql`, {
        query: `query {
        apps {
          id name endpoint dbColumnNames
          dateRanges { attribution { min max } potential { min max } }
          networks { id regions { id } }
          metrics {
            name displayName shortDisplayName description ranged summable displayFormat displayDecimals displayPrefix displaySuffix
            suitableForDashboard suitableForTopline suitableForHeadline suitableForTimeseriesChart suitableForComparisonTableColumn suitableForSpendBenefitComparison suitableForRawDataTableColumn
            suitableForRecommendedSpendPredictedOutcomesTimeseriesChart suitableForSummablePrimaryGoal
            defaultForDashboard defaultForTopline defaultForHeadline defaultForTimeseriesChart defaultForComparisonTableColumn defaultForSpendBenefitComparison defaultForRawDataTableColumn
            defaultForRecommendedSpendPredictedOutcomesTimeseriesChart defaultForSummablePrimaryGoal
            neutral negativeIsGood
          }
        }
      }`
      }));

      const data = result?.data?.apps || [];

      this._metadata$.next(data);

      if (this.cacheTimeout) { clearTimeout(this.cacheTimeout); }
      this.cacheTimeout = setTimeout(() => this.invalidate(), 3600000) as any;

      resolve(data);
      this.loading = undefined;
    });
    return this.loading;
  }

  public async getById(id: string) {
    return (await this.get()).find(x => x.id === id);
  }

  public invalidate() {
    // If we have no subscribers, just invalidate the observable so that the next subscription will cause the data to be re-requested - otherwise re-request the data
    if (!this._metadata$?.observed) { this._metadata$?.next(undefined); } else { this.get(true); }
  }

}
