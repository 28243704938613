import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from './access-denied.component';
import { ErrorComponent } from './error.component';
import { NotFoundComponent } from './not-found.component';
import { EmailUnverifiedComponent } from './email-unverified.component';
import { NoActiveOrganisationComponent } from './no-active-organisation.component';
import { ResourceNotFoundComponent } from './resource-not-found.component';

const routes: Routes = [
  { path: 'error', component: ErrorComponent, data: { redirectOnRefresh: true, bypassUnsavedChanges: true } },
  { path: 'access-denied', component: AccessDeniedComponent, data: { redirectOnRefresh: true, bypassUnsavedChanges: true } },
  { path: 'email-unverified', component: EmailUnverifiedComponent, data: { redirectOnRefresh: true } },
  { path: 'no-active-organisation', component: NoActiveOrganisationComponent, data: { redirectOnRefresh: true } },
  { path: 'resource-not-found', component: ResourceNotFoundComponent, data: { redirectOnRefresh: true, bypassUnsavedChanges: true } },
  { path: 'not-found', component: NotFoundComponent, data: { redirectOnRefresh: true, bypassUnsavedChanges: true } },
  { path: '**', component: NotFoundComponent, data: { redirectOnRefresh: true, bypassUnsavedChanges: true } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorsRoutingModule {}
