<app-page-header [title]="title.getTitle()"></app-page-header>
<section class="page-area">
  <div class="padded-content top-bottom-padding">
    <app-content-header title="Please verify your email address"></app-content-header>
    <p>
      Thanks for signing up. It appears you have not yet completed the signup procedure. To do so, you must verify your email address.
      You should have received an email about this. Please click on the link in that email or if you can't find the email, click the button below to resend it.
    </p>
    <p class="buttons">
      <a (click)="resendVerificationEmail()" class="btn btn-primary" href="javascript:void(0);">Resend Verification Email</a>
      <a (click)="hardReload()" class="btn btn-secondary" href="javascript:void(0);">Return to the dashboard</a>
    </p>
  </div>
</section>
