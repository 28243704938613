import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private storageKey = 'app.theme';

  public constructor() {
    this._dark = localStorage.getItem(this.storageKey) === 'dark' || (!localStorage.getItem(this.storageKey) && !!window.matchMedia('(prefers-color-scheme: dark)'));
  }

  private _dark = false;

  public get dark() {
    if (isNil(this._dark)) { this._dark = localStorage.getItem(this.storageKey) === 'dark' || (!localStorage.getItem(this.storageKey) && !!window.matchMedia('(prefers-color-scheme: dark)')); }
    return this._dark;
  }

  public set dark(value: boolean) {
    if (value === this._dark) { return; }
    this._dark = value;
    if (this._dark) {
      document.body.classList.add('dark');
      localStorage.setItem(this.storageKey, 'dark');
    } else {
      document.body.classList.remove('dark');
      localStorage.setItem(this.storageKey, 'light');
    }
    this._changed$.next({ dark: value });
  }

  private _changed$ = new Subject<{ dark: boolean }>();

  public get changed$() {
    return this._changed$.asObservable();
  }

}
