export class Permissions {

  public static readonly debug: string = 'd';
  public static readonly admin: string = 'a';
  public static readonly configuration: string = 'c';
  public static readonly developer: string = 'v';
  public static readonly users: string = 'u';

  public static all = [
    Permissions.debug,
    Permissions.admin,
    Permissions.configuration,
    Permissions.developer,
    Permissions.users
  ];

}
