import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, flatMap, groupBy, keyBy, mapValues, orderBy, uniq, uniqBy } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAppMetricsDto } from '../../api-model/app-metrics-dto';
import { IAppOverviewMetricsDto } from '../../api-model/app-overview-metrics-dto';
import { IAttributionMetricsResultDto } from '../../api-model/attribution-metrics-response-dto';
import { IInfluenceDto } from '../../api-model/influence-dto';
import { NetworksDataService } from '../../networks/networks-data.service';
import { AttributionQueryHelperService } from '../../shared/services/attribution-query-helper.service';
import { AnalysisSettingsService } from '../shared/analysis-settings.service';
import { AppsAnalysisMetadataService } from './apps-analysis-metadata.service';

@Injectable({
  providedIn: 'root'
})
export class AppsAnalysisDataService {

  public constructor(
    private readonly http: HttpClient,
    private readonly appsAnalysisMetadataService: AppsAnalysisMetadataService,
    private readonly queryHelper: AttributionQueryHelperService,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly networksDataService: NetworksDataService
  ) { }

  public async get(metricNamesByAppId: {
    [appId: string]: string[]
  }): Promise<IAppOverviewMetricsDto[]> {
    const metadata = await this.appsAnalysisMetadataService.get();

    return (await Promise.all(metadata.map(async app => {
      const metrics = app.metrics.filter(x => metricNamesByAppId[app.id].includes(x.name) || x.name === 'spend');
      const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);

      const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
        query: `query {
        spendByNetwork: attribution(options: {${cadenceOptions.recentPastDates}}) { results { networkId spend } }
        spendByRegion: attribution(options: {${cadenceOptions.recentPastDates}}) { results { regionId spend } }
        timeseries: attribution(options: {${cadenceOptions.recentPastDates}${cadenceOptions.periodicity}}) {
          results { date ${metrics.map(x => `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`).join(' ')}}
        }
      }`
      }));

      const timeseries = result?.data?.timeseries.results as IAttributionMetricsResultDto[];

      return {
        appId: app.id,
        spendBreakdown: {
          byNetwork: mapValues(keyBy(
            orderBy(result?.data?.spendByNetwork.results as IAttributionMetricsResultDto[] || [], x => x.spend, 'desc')
              .filter(x => x.networkId && x.spend), x => x.networkId), x => x.spend || 0),
          byRegion: mapValues(keyBy(
            orderBy(result?.data?.spendByRegion.results as IAttributionMetricsResultDto[] || [], x => x.spend, 'desc')
              .filter(x => x.regionId && x.spend), x => x.regionId), x => x.spend || 0)
        },
        metrics: metrics.map(metric => this.queryHelper.mapMetric(timeseries, metric, this.analysisSettings.cadence, this.analysisSettings.dateRange))
      } as IAppOverviewMetricsDto;
    }))).filter(x => x.metrics.some(m => m?.trend?.some(s => s.value)));
  }

  public async getByAppId(appId: string, metricNames: string[]): Promise<IAppMetricsDto> {
    const app = await this.appsAnalysisMetadataService.getById(appId);
    if (!app) { return null; }

    const metrics = app.metrics.filter(x => metricNames.includes(x.name) || x.name === 'spend');

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        attribution(options: {${cadenceOptions.recentPastAndNearFutureDates}${cadenceOptions.periodicity}}) {
          results { date ${metrics.map(x => `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`).join(' ')}}
        }
        potential(options: {${cadenceOptions.recentPastAndNearFutureDates}${cadenceOptions.periodicity}}) {
          results { date ${flatMap(metrics.map(x => x.ranged
        ? [
          `${x.name}Min(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`,
          `${x.name}Max(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`
        ]
        : `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`)).join(' ')}}
        }
      }`
    }));

    const attribution = keyBy(result?.data?.attribution.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto
    };
    const potential = keyBy(result?.data?.potential.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto
    };
    const timeseries = uniq(Object.keys(attribution).concat(Object.keys(potential))).sort().map(date => ({
      date: attribution[date]?.date || potential[date]?.date,
      attribution: attribution[date],
      potential: potential[date]
    }));

    return {
      appId,
      metrics: metrics.map(metric => metric.ranged
        ? this.queryHelper.mapMetricWithTargetRange(timeseries, metric, this.analysisSettings.cadence, this.analysisSettings.dateRange)
        : this.queryHelper.mapMetricWithTarget(timeseries, metric, this.analysisSettings.cadence, this.analysisSettings.dateRange))
    };
  }

  public async getInfluences(appId: string): Promise<IInfluenceDto[]> {
    const app = await this.appsAnalysisMetadataService.getById(appId);
    if (!app) { return null; }

    const metrics = app.metrics.filter(x => x.suitableForSummablePrimaryGoal);
    const spendMetric = app.metrics.find(x => x.name === 'spend');
    metrics.push(spendMetric);

    const cadenceOptions = this.queryHelper.getQueryOptionsForCadence(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    const result = await firstValueFrom(this.http.post<any>(app.endpoint, {
      query: `query {
        byNetwork: attribution(options: {${cadenceOptions.recentPastDates}${cadenceOptions.periodicity}}) {
          results { date networkId networkName ${metrics.map(x => `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`).join(' ')}}
        }
        byOrganicPublisher: attribution(options: {${cadenceOptions.recentPastDates}${cadenceOptions.periodicity}, networkIds: ["${environment.organicNetworkId}"]}) {
          results { date publisherName ${metrics.map(x => `${x.name}(transform: EXPONENTIAL_MOVING_AVERAGE, lookback: ${this.analysisSettings.smoothingPeriod})`).join(' ')}}
        }
      }`
    }));

    const byNetwork = groupBy(result?.data?.byNetwork.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto[]
    };
    const byOrganicPublisher = groupBy(result?.data?.byOrganicPublisher.results as IAttributionMetricsResultDto[], x => x.date.toISOString()) as {
      [date: string]: IAttributionMetricsResultDto[]
    };
    const timeseries = uniq(Object.keys(byNetwork).concat(Object.keys(byOrganicPublisher))).sort().map(date => ({
      date: first(byNetwork[date])?.date || first(byOrganicPublisher[date])?.date,
      byNetwork: keyBy(byNetwork[date], x => x.networkId),
      byOrganicPublisher: keyBy(byOrganicPublisher[date], x => x.publisherName)
    }));

    const networks = await this.networksDataService.get();

    const influences = uniqBy((result?.data?.byOrganicPublisher.results || []) as IAttributionMetricsResultDto[], x => x.publisherName).map(x => ({
      name: x.publisherName,
      metrics: mapValues(keyBy(metrics, x => x.name), m => timeseries.map(t => ({
        date: t.date,
        value: (t.byOrganicPublisher[x.publisherName] || {})[m.name] || 0
      })))
    } as IInfluenceDto)).concat(uniqBy((result?.data?.byNetwork.results || []) as IAttributionMetricsResultDto[], x => x.networkId).map(x => ({
      name: networks.find(n => n.id === x.networkId)?.displayName || x.networkName,
      network: networks.find(n => n.id === x.networkId) || {
        id: x.networkId,
        displayName: x.networkName
      },
      metrics: mapValues(keyBy(metrics, x => x.name), m => timeseries.map(t => ({
        date: t.date,
        value: (t.byNetwork[x.networkId] || {})[m.name] || 0
      })))
    } as IInfluenceDto))).filter(x => Object.values(x.metrics).some(m => m.some(d => d.value)));

    influences.filter(x => x.name.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'naturaldemand').forEach(x => x.isNaturalDemand = true);

    return orderBy(influences, [x => !x.network, x => x.isNaturalDemand]);
  }

}
