import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Cadence } from '../../api-model/enums/cadence';
import { ShortCurrencyPipe } from './short-currency.pipe';

@Pipe({
  name: 'recommendedAction'
})
export class RecommendedActionPipe implements PipeTransform {

  constructor(private readonly percent: PercentPipe, private readonly shortCurrency: ShortCurrencyPipe) { }

  public transform(percentageChange: number, recommendedSpend: number, cadence: Cadence = Cadence.Daily, bauSpendNextPeriod: number): string {
    let cls: string;
    let iconCls: string;
    let text: string;
    let showChange = true;

    let cadenceCls = 'daily';

    switch (cadence) {
      case Cadence.Weekly:
        cadenceCls = 'weekly';
        break;
      case Cadence.Monthly:
        cadenceCls = 'monthly';
        break;
    }

    if (percentageChange > 0.01) {
      cls = 'up';
      iconCls = 'fa-solid fa-arrow-up';
      text = 'Increase spend to';
    } else if (percentageChange < -0.01) {
      cls = 'down';
      iconCls = 'fa-solid fa-arrow-down';
      text = 'Decrease spend to';
    } else if (recommendedSpend) {
      cls = '';
      iconCls = 'fa-solid fa-check';
      text = 'Maintain spend';
    } else if (bauSpendNextPeriod) {
      cls = 'down';
      iconCls = 'fa-solid fa-arrow-down';
      text = 'Decrease spend to';
    } else {
      text = 'Spend data not available';
      showChange = false;
    }

    let detail = '';
    let change = '';

    if (showChange) {
      change = `<span class="change"><span class="by"></span><strong class="percentage">${this.shortCurrency.transform(recommendedSpend, 'USD', 2)}</strong></span>`;
    }
    return text ? `<div class="recommended-action-wrap"><div class="${cls} recommended-action"><span><i class="${iconCls}"></i> <span class="instruction"></span>${text}</span>${change}</div>${detail}</div>` : '';
  }

}
