import { Component, Input } from '@angular/core';
import { IAttributionMetadataMetricDto } from '../api-model/attribution-metadata-metric-dto';

@Component({
  selector: 'app-metric-current-value',
  templateUrl: './metric-current-value.component.html',
  styleUrls: ['./metric-current-value.component.scss']
})
export class MetricCurrentValueComponent {

  @Input() public config: IAttributionMetadataMetricDto;

  @Input() public value = 0;

  @Input() public dashIfNoValue = false;

}
