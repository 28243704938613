<ngx-loading-bar
  [includeSpinner]="false"
  [height]="height"
  [color]="colour"
  [fixed]="fixed"
  *ngIf="enabled"></ngx-loading-bar>
<div *ngIf="disableInput && (loader.value$|async)" class="overlay"></div>
<div *ngIf="!hideContentUntilFirstRouteLoaded || !firstRouteLoading">
  <ng-content></ng-content>
</div>
