import { PercentPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash-es';

@Injectable()
@Pipe({ name: 'neutralPercentageChange' })
export class NeutralPercentageChangePipe implements PipeTransform {

  public constructor(private readonly percentPipe: PercentPipe) {}

  public transform(value: number, digitsInfo = '1.0-1'): string {
    const roundedValue = round(value * 100);
    if (!roundedValue) { return ''; }
    return `<em class="percentage-change">${roundedValue > 0 ? '<i class="fa-solid fa-caret-up"></i> +' : '<i class="fa-solid fa-caret-down"></i> '}${this.percentPipe.transform(value, digitsInfo)}</em>`;
  }

}
