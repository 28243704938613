import { Injectable } from '@angular/core';
import { findLast, first, last } from 'lodash-es';
import moment from 'moment/moment';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { IAttributionMetricsResultDto } from '../../api-model/attribution-metrics-response-dto';
import { Cadence } from '../../api-model/enums/cadence';
import { IMetricDto } from '../../api-model/metric-dto';
import { IMetricWithTargetDto } from '../../api-model/metric-with-target-dto';
import { IMetricWithTargetRangeDto } from '../../api-model/metric-with-target-range-dto';
import { ITrendDateDto } from '../../api-model/trend-date-dto';
import { ITrendDateWithTargetDto } from '../../api-model/trend-date-with-target-dto';
import { ITrendDateWithTargetRangeDto } from '../../api-model/trend-date-with-target-range-dto';
import { DateRange } from '../date-range';

@Injectable({
  providedIn: 'root'
})
export class AttributionQueryHelperService {

  public mapMetric(timeseries: IAttributionMetricsResultDto[], metric: IAttributionMetadataMetricDto, cadence: Cadence, dateRange: DateRange): IMetricDto {
    if (!timeseries.length) { return null; }
    const start = first(timeseries);
    let mostRecent = this.getMostRecent(cadence, dateRange);
    const mostRecentAttribution = timeseries.find(x => x.date.valueOf() === mostRecent.valueOf()) || last(timeseries);
    const end = last(timeseries);

    return {
      config: metric,
      current: mostRecentAttribution ? mostRecentAttribution[metric.name] || 0 : 0,
      trend: timeseries.map(x => ({
        date: x.date,
        value: x[metric.name] || 0,
        hasAttributionData: true
      }) as ITrendDateDto),
      percentageChange: start && end && start[metric.name] && end[metric.name]
        ? 0 - (((start[metric.name] || 0) - end[metric.name]) / (start[metric.name] || 0))
        : 0,
      hasTrend: timeseries.length >= 2 && timeseries.some(x => x[metric.name])
    };
  }

  public mapPotentialMetric(timeseries: IAttributionMetricsResultDto[], metric: IAttributionMetadataMetricDto, cadence: Cadence, dateRange: DateRange): IMetricDto {
    if (!timeseries.length) { return null; }
    const start = first(timeseries);
    let mostRecent = this.getMostRecent(cadence, dateRange);
    const mostRecentAttribution = timeseries.find(x => x.date.valueOf() === mostRecent.valueOf()) || last(timeseries);
    const end = last(timeseries);

    const minMetricName = `${metric.name}Min`;
    const maxMetricName = `${metric.name}Max`;

    const currentVal = (mr: IAttributionMetricsResultDto) => {
      if (!metric.ranged)
        return mr[metric.name] || 0;
      return ((mr[maxMetricName] || 0) + (mr[minMetricName] || 0)) / 2
    };

    const currentValDeviation = (mr: IAttributionMetricsResultDto) => {
      if (!metric.ranged)
        return 0;
      return ((mr[maxMetricName] || 0) - (mr[minMetricName] || 0)) / 2
    };

    const percentageChange = () => {
      if (!metric.ranged) {
        return start[metric.name] && end[metric.name]
          ? 0 - ((start[metric.name] - end[metric.name]) / start[metric.name])
          : 0;
      } else {
        let stVal = currentVal(start);
        return stVal ? 0 - (stVal - currentVal(end)) / stVal : 0;
      }
    }

    return {
      config: metric,
      current: currentVal(mostRecentAttribution),
      currentDeviation: currentValDeviation(mostRecentAttribution),
      trend: timeseries.map(x => ({
        date: x.date,
        value: currentVal(x),
        hasAttributionData: true
      }) as ITrendDateDto),
      percentageChange: percentageChange(),
      hasTrend: timeseries.length >= 2 && timeseries.some(x => x[metric.name])
    };
  }

  public mapMetricWithTarget(timeseries: {
    date: Date,
    attribution: IAttributionMetricsResultDto,
    potential: IAttributionMetricsResultDto
  }[], metric: IAttributionMetadataMetricDto, cadence: Cadence, dateRange: DateRange): IMetricWithTargetDto {
    if (!timeseries.length) { return null; }

    const startAttribution = timeseries.find(x => !!x.attribution)?.attribution;
    let mostRecent = this.getMostRecent(cadence, dateRange);
    const mostRecentAttribution = timeseries.find(x => x.date.valueOf() === mostRecent.valueOf())?.attribution || findLast(timeseries, x => !!x.attribution)?.attribution;
    const mostRecentPotential = timeseries.find(x => x.date.valueOf() === mostRecent.valueOf())?.potential || findLast(timeseries, x => !!x.potential)?.potential;

    return {
      config: metric,
      current: mostRecentAttribution ? mostRecentAttribution[metric.name] || 0 : 0,
      trend: timeseries.map(x => ({
        date: x.date,
        value: x.attribution ? x.attribution[metric.name] || 0 : 0,
        target: x.potential ? x.potential[metric.name] || 0 : 0,
        hasAttributionData: !!x.attribution,
        hasPotentialData: !!x.potential
      }) as ITrendDateWithTargetDto),
      percentageChange: startAttribution && mostRecentAttribution && startAttribution[metric.name] && mostRecentAttribution[metric.name]
        ? 0 - (((startAttribution[metric.name] || 0) - mostRecentAttribution[metric.name]) / (startAttribution[metric.name] || 0))
        : 0,
      target: mostRecentPotential ? mostRecentPotential[metric.name] : 0,
      hasTrend: timeseries.length >= 2 && timeseries.some(x => x.attribution && x.attribution[metric.name])
    };
  }

  public mapMetricWithTargetRange(timeseries: {
    date: Date,
    attribution: IAttributionMetricsResultDto,
    potential: IAttributionMetricsResultDto
  }[], metric: IAttributionMetadataMetricDto, cadence: Cadence, dateRange: DateRange): IMetricWithTargetRangeDto {
    if (!timeseries.length) { return null; }

    const startAttribution = timeseries.find(x => !!x.attribution)?.attribution;
    let mostRecent = this.getMostRecent(cadence, dateRange);
    const mostRecentAttribution = timeseries.find(x => x.date.valueOf() === mostRecent.valueOf())?.attribution || findLast(timeseries, x => !!x.attribution)?.attribution;
    const mostRecentPotential = timeseries.find(x => x.date.valueOf() === mostRecent.valueOf())?.potential || findLast(timeseries, x => !!x.potential)?.potential;

    return {
      config: metric,
      current: mostRecentAttribution ? mostRecentAttribution[metric.name] || 0 : 0,
      trend: timeseries.map(x => ({
        date: x.date,
        value: x.attribution ? x.attribution[metric.name] || 0 : 0,
        targetMin: x.potential ? x.potential[`${metric.name}Min`] || 0 : 0,
        targetMax: x.potential ? x.potential[`${metric.name}Max`] || 0 : 0,
        hasAttributionData: !!x.attribution,
        hasPotentialData: !!x.potential
      }) as ITrendDateWithTargetRangeDto),
      percentageChange: startAttribution && mostRecentAttribution && startAttribution[metric.name] && mostRecentAttribution[metric.name]
        ? 0 - (((startAttribution[metric.name] || 0) - mostRecentAttribution[metric.name]) / (startAttribution[metric.name] || 0))
        : 0,
      targetMin: mostRecentPotential ? mostRecentPotential[`${metric.name}Min`] : 0,
      targetMax: mostRecentPotential ? mostRecentPotential[`${metric.name}Max`] : 0,
      hasTrend: timeseries.length >= 2 && timeseries.some(x => (x.attribution && x.attribution[metric.name]) || (x.potential && x.potential[`${metric.name}Max`]))
    };
  }

  public getMostRecent(cadence: Cadence, dateRange: DateRange): Date {
    let mostRecent: Date;
    switch (cadence) {
      case Cadence.Weekly:
        mostRecent = dateRange.end.startOf('isoWeek').toDate();
        break;
      case Cadence.Monthly:
        mostRecent = dateRange.end.startOf('month').toDate();
        break;
      default:
        mostRecent = dateRange.end.startOf('day').toDate();
        break;
    }
    return mostRecent;
  }

  public getSpendAdviceDates(cadence: Cadence) {
    switch (cadence) {
      case Cadence.Weekly:
        return {
          start: moment.utc().add(1, 'week').startOf('isoWeek'),
          end: moment.utc().add(12, 'week').endOf('isoWeek')
        };
      case Cadence.Monthly:
        return {
          start: moment.utc().add(1, 'month').startOf('month'),
          end: moment.utc().add(12, 'months').endOf('month')
        };
      default:
        return {
          start: moment.utc().add(1, 'day').startOf('day'),
          end: moment.utc().add(30, 'days').endOf('day')
        };
    }
  }

  public getRecentPastDates(cadence: Cadence, dateRange: DateRange) {
    switch (cadence) {
      case Cadence.Weekly:
        return {
          start: dateRange.start.startOf('isoWeek'),
          end: dateRange.end.endOf('isoWeek').endOf('day')
        };
      case Cadence.Monthly:
        return {
          start: dateRange.start.startOf('month'),
          end: dateRange.end.endOf('month').endOf('day')
        };
      default:
        return {
          start: dateRange.start.startOf('day'),
          end: dateRange.end.clone().subtract(1, 'day').endOf('day')
        };
    }
  }

  public getNearFutureDates(cadence: Cadence, dateRange: DateRange) {
    switch (cadence) {
      case Cadence.Weekly:
        {
          const currentWeekStartDate = moment.utc().startOf('isoWeek');
          if (currentWeekStartDate.diff(dateRange.end, "seconds") > 0)
            return {
              start: dateRange.end.startOf('isoWeek'),
              end: dateRange.end.endOf('isoWeek')
            };
          else
            return {
              start: moment.utc().add(1, 'week').startOf('isoWeek'),
              end: moment.utc().add(4, 'week').endOf('isoWeek')
            };
        }

      case Cadence.Monthly:
        {
          const currentMonthStartDate = moment.utc().startOf('month');
          if (currentMonthStartDate.diff(dateRange.end, "seconds") > 0)
            return {
              start: dateRange.end.startOf('month'),
              end: dateRange.end.endOf('month')
            };
          else
            return {
              start: moment.utc().add(1, 'month').startOf('month'),
              end: moment.utc().add(3, 'month').endOf('month')
            };
        }
      default:
        {
          const currentDate = moment.utc().startOf('day');
          if (currentDate.diff(dateRange.end, "seconds") > 0)
            return {
              start: dateRange.end.startOf('day'),
              end: dateRange.end.endOf('day')
            };
          else
            return {
              start: moment.utc().add(1, 'day').startOf('day'),
              end: moment.utc().add(14, 'day').endOf('day')
            };
        }
    }
  }

  public getRecommendationRecentDates(cadence: Cadence) {
    switch (cadence) {
      case Cadence.Weekly:
        return {
          start: moment.utc().subtract(2, 'week').endOf('isoWeek'),
          end: moment.utc().subtract(1, 'week').startOf('isoWeek')
        };
      case Cadence.Monthly:
        return {
          start: moment.utc().subtract(2, 'month').endOf('month'),
          end: moment.utc().subtract(1, 'month').startOf('month')
        };
      default:
        return {
          start: moment.utc().subtract(2, 'day').endOf('day'),
          end: moment.utc().subtract(1, 'day').startOf('day'),
        };
    }
  }

  public getRecommendationDates(cadence: Cadence) {
    switch (cadence) {
      case Cadence.Weekly:
        return {
          start: moment.utc().startOf('isoWeek'),
          end: moment.utc().add(12, 'week').endOf('isoWeek')
        };
      case Cadence.Monthly:
        return {
          start: moment.utc().startOf('month'),
          end: moment.utc().add(3, 'month').endOf('month')
        };
      default:
        return {
          start: moment.utc().startOf('day'),
          end: moment.utc().add(31, 'day').endOf('day')
        };
    }
  }

  public getNextPeriodDates(cadence: Cadence, dateRange: DateRange) {
    switch (cadence) {
      case Cadence.Weekly:
        {
          if (moment.utc().startOf('isoWeek').diff(dateRange.end, "week") > 0)
            return {
              start: dateRange.end.startOf('isoWeek'),
              end: dateRange.end.endOf('isoWeek')
            };
          else
            return {
              start: moment.utc().add(1, 'week').startOf('isoWeek'),
              end: moment.utc().add(1, 'week').endOf('isoWeek')
            };
        }

      case Cadence.Monthly:
        {
          if (moment.utc().startOf('month').diff(dateRange.end, "month") > 0)
            return {
              start: dateRange.end.startOf('month'),
              end: dateRange.end.endOf('month')
            };
          else
            return {
              start: moment.utc().add(1, 'month').startOf('month'),
              end: moment.utc().add(1, 'month').endOf('month')
            };
        }
      default:
        {
          if (moment.utc().startOf('day').diff(dateRange.end, "day") > 0)
            return {
              start: dateRange.end.startOf('day'),
              end: dateRange.end.endOf('day')
            };
          else
            return {
              start: moment.utc().add(1, 'day').startOf('day'),
              end: moment.utc().add(1, 'day').endOf('day')
            };
        }
    }
  }

  public getPeriodicityForCadence(cadence: Cadence) {
    switch (cadence) {
      case Cadence.Weekly:
        return ', periodicity: WEEK';
      case Cadence.Monthly:
        return ', periodicity: MONTH';
      default:
        return ', periodicity: DAY';
    }
  }

  public getQueryOptionsForCadence(cadence: Cadence, dateRange: DateRange) {
    const recentPastDates = this.getRecentPastDates(cadence, dateRange);
    const nextPeriodDates = this.getNextPeriodDates(cadence, dateRange);
    const nearFutureDates = this.getNearFutureDates(cadence, dateRange);
    const recommendationDates = this.getRecommendationDates(cadence);
    const recommendationRecentDates = this.getRecommendationRecentDates(cadence);
    return {
      recentPastDates: `start: "${recentPastDates.start.format('YYYY-MM-DD')}" end: "${recentPastDates.end.format('YYYY-MM-DD')}"`,
      recentPastAndNextPeriodDates: `start: "${recentPastDates.start.format('YYYY-MM-DD')}" end: "${nextPeriodDates.end.format('YYYY-MM-DD')}"`,
      recentPastAndNearFutureDates: `start: "${recentPastDates.start.format('YYYY-MM-DD')}" end: "${nearFutureDates.end.format('YYYY-MM-DD')}"`,
      nextPeriodDates: `start: "${nextPeriodDates.start.format('YYYY-MM-DD')}" end: "${nextPeriodDates.end.format('YYYY-MM-DD')}"`,
      recommendationDates: `start: "${recommendationDates.start.format('YYYY-MM-DD')}" end: "${recommendationDates.end.format('YYYY-MM-DD')}"`,
      recommendationRecentDates: `start: "${recommendationRecentDates.start.format('YYYY-MM-DD')}" end: "${recommendationRecentDates.end.format('YYYY-MM-DD')}"`,
      periodicity: this.getPeriodicityForCadence(cadence)
    };
  }

  public getSpendAdviceQueryOptionsForCadence(cadence: Cadence) {
    const dates = this.getSpendAdviceDates(cadence);
    return {
      dates: `start: "${dates.start.format('YYYY-MM-DD')}" end: "${dates.end.format('YYYY-MM-DD')}"`,
      periodicity: this.getPeriodicityForCadence(cadence)
    };
  }

}
