import { CommonModule } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found.component';
import { SharedModule } from '../shared/shared.module';
import { EmailUnverifiedComponent } from './email-unverified.component';
import { NoActiveOrganisationComponent } from './no-active-organisation.component';
import { ErrorHandlerService } from './error-handler.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { ErrorComponent } from './error.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ResourceNotFoundComponent } from './resource-not-found.component';

@NgModule({
  declarations: [
    ErrorComponent,
    NotFoundComponent,
    NoActiveOrganisationComponent,
    EmailUnverifiedComponent,
    NoActiveOrganisationComponent,
    NotFoundComponent,
    ResourceNotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    PipesModule
  ]
})
export class ErrorsModule {
  public static forRoot(): ModuleWithProviders<ErrorsModule> {
    return {
      ngModule: ErrorsModule,
      providers: [
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true } // Handles 500
      ]
    };
  }
}
