import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  public constructor(public title: Title, private readonly auth: AuthService) {
    this.title.setTitle('Logged out');
  }

  public ngOnInit() { }

  public login() {
    this.auth.login();
  }

}
