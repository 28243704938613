import { Injectable } from '@angular/core';
import { LocalStorageService as NgLocalStorageService } from 'angular-2-local-storage';

/**
 * A light wrapper around NgLocalStorageService just to set the storage type and prefix so that
 * we can use both session storage and local storage in the same app without setting these values
 * all over the place.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends NgLocalStorageService {
  public constructor() {
    super({
      storageType: 'localStorage',
      prefix: 'app'
    });
  }
}
