<ul>
  <li *ngIf="authenticated">
    <div class="profile-link">
      <a #popover="bs-popover" [popover]="profilePopoverTemplate" adaptivePosition="false" container="body" containerClass="identity-popover" outsideClick="true" placement="bottom">
        {{name}}
        <span *ngIf="profile?.activeAdvertiser?.displayName"> - {{profile?.activeAdvertiser?.displayName}}</span>
        <img *ngIf="profile?.picture" alt="avatar" class="avatar" referrerpolicy="no-referrer" src="{{profile?.picture}}" />
      </a>
      <ng-template #profilePopoverTemplate>
        <div class="profile-popover">
          <img *ngIf="profile?.picture" alt="avatar" class="avatar" src="{{profile?.picture}}" />
          <div class="main-details">
            <h2>{{profile?.name}}</h2>
            <p>{{profile?.email}}</p>
            <p>{{profile?.activeAdvertiser?.displayName}}</p>
          </div>
          <div *ngIf="profile?.otherAdvertisers?.length" class="other-advertisers">
            <p>Switch to:</p>
            <div class="inner">
              <h3>Advertisers</h3>
              <ul>
                <li *ngFor="let advertiser of switchToAdvertisers">
                  <a (click)="popover.hide();switchAdvertiser(advertiser)" href="javascript:void(0);"><i class="fa fa-user-circle"></i> {{advertiser.displayName}}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="button-row">
            <button (click)="popover.hide();logout()" class="btn btn-primary btn-sm">Log out</button>
            <button (click)="popover.hide()" class="btn btn-secondary btn-sm" routerLink="/profile">Profile</button>
            <div class="theme">
              <span>Dark Mode:</span>
              <ui-switch [(ngModel)]="dark"></ui-switch>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </li>
  <li *ngIf="!authenticated">
    <a (click)="login()" class="clickable">Login</a>
  </li>
</ul>
