import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationMessagesService } from './validation-messages.service';

@Component({
  selector: 'app-form-validation-messages',
  templateUrl: './form-validation-messages.component.html',
  styleUrls: ['./form-validation-messages.component.scss']
})
export class FormValidationMessagesComponent {

  @Input() public floating: boolean;
  @Input() public hideIfPristine = true;
  @Input() public model: AbstractControl;

  public constructor(private readonly validationMessagesService: ValidationMessagesService) {}

  public get validationMessages() {
    return this.validationMessagesService.getValidationMessages(this.model, this.hideIfPristine) || [];
  }

}
