import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Data, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

/**
 * Guards access to a route by testing that the authenticated user has the
 * required permissions to access it. Requires the route to have a data property
 * defined as follows:
 *     data: { requiredScopes: [Permissions.SomePermission, Permissions.SomeOtherPermission] }
 * The default behaviour is to require all the scopes defined in requiredScopes.
 * To change this to require any of them, set requiredScopesAllowAny to true in the scope data:
 *     data: {
 *        requiredScopes: [Permissions.SomePermission, Permissions.SomeOtherPermission],
 *        requiredScopesAllowAny: true
 *     }
 * If the guard test fails and the user doesn't have the required scopes, the user will be
 * redirected to AccessDeniedComponent. It will also implicitly force the user to log in
 * if they haven't done so already
 */
@Injectable({
  providedIn: 'root'
})
export class ScopeGuard implements CanActivate, CanActivateChild, CanLoad {

  public constructor(private readonly auth: AuthService, public router: Router) { }

  // noinspection JSUnusedLocalSymbols
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard(next.data);
  }

  // noinspection JSUnusedLocalSymbols
  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard(next.data);
  }

  // noinspection JSUnusedLocalSymbols
  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard(route.data);
  }

  public async guard(data: Data) {
    const scopes = (data as any).requiredScopes as string[];
    const allowAny = (data as any).requiredScopesAllowAny as boolean || false;
    return this.auth.ensureUserHasScopes(scopes, allowAny);
  }

}
