import { PercentPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({ name: 'percentageDifferenceFrom' })
export class PercentageDifferenceFromPipe implements PipeTransform {

  public constructor(private readonly percentPipe: PercentPipe) {}

  public transform(value: number, valueFrom?: number, digitsInfo = '1.0-1', negativeIsGood = false, neutral = false): string {
    if (!value || !valueFrom) { return ''; }
    const change = (value / valueFrom) - 1;
    const positiveClass = neutral ? '' : negativeIsGood ? 'bad ' : 'good ';
    const negativeClass = neutral ? '' : negativeIsGood ? 'good ' : 'bad ';
    return `<em class="${change > 0 ? positiveClass : change < 0 ? negativeClass : ''}percentage-difference">${change > 0 ? '+' : ''}${this.percentPipe.transform(change, digitsInfo)}</em>`;
  }

}
