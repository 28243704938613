import { Auth0Identity, Auth0UserProfile } from 'auth0-js';
import { extend, orderBy } from 'lodash-es';
import { IUserOrganisationDto } from 'src/app/api-model/user-organisation-dto';
import { IAppMetadata } from './app-metadata';
import { AppClaimTypes } from './app-claim-types';
import { IUserMetadata } from './user-metadata';

export class AppUserProfile implements Auth0UserProfile {

  public name: string;

  public nickname: string;

  public picture: string;

  public user_id: string;

  public username?: string;

  public given_name?: string;

  public family_name?: string;

  public email?: string;

  public email_verified?: boolean;

  public clientID: string;

  public gender?: string;

  public locale?: string;

  public identities: Auth0Identity[];

  public created_at: string;

  public updated_at: string;

  public sub: string;

  public user_metadata?: IUserMetadata; // Blank because not OIDC-compliant.

  public app_metadata?: IAppMetadata; // See https://community.auth0.com/t/the-app-metadata-is-not-included-in-id-token/7830

  public activeAdvertiser: IUserOrganisationDto = {};

  public otherAdvertisers: IUserOrganisationDto[] = [];

  public constructor(profile: Auth0UserProfile = {} as Auth0UserProfile) {
    extend(this, profile);
  }

  public get allAdvertisers() {
    return orderBy([this.activeAdvertiser].concat(this.otherAdvertisers || []).filter(x => x?.id),
      [x => x.subscriptionTier, x => x.displayName], ['desc', 'asc']);
  }

  public get advertiserIds(): string[] {
    const ids = (this[AppClaimTypes.advertiserIds] as string || '').split(';').filter(x => x);
    return ids.length ? ids : this.allAdvertisers?.map(x => x.id) ?? [];
  }

  public get activeAdvertiserId(): string { return this[AppClaimTypes.activeAdvertiserId] || ''; }

  public get internalUser(): boolean { return !!this[AppClaimTypes.internalUser]; }

  public get anonymisedDataOnly(): boolean { return !!this[AppClaimTypes.anonymisedDataOnly]; }

  public reset() {
    this.name = null;
    this.nickname = null;
    this.picture = null;
    this.user_id = null;
    this.username = null;
    this.given_name = null;
    this.family_name = null;
    this.email = null;
    this.email_verified = null;
    this.clientID = null;
    this.gender = null;
    this.locale = null;
    this.identities = null;
    this.created_at = null;
    this.updated_at = null;
    this.sub = null;
    this.user_metadata = null;
    this.app_metadata = null;
    this.activeAdvertiser = {};
    this.otherAdvertisers = [];
  }

}
