import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  public constructor(auth: AuthService, route: ActivatedRoute) {
    auth.signUp(route.snapshot.params.email, route.snapshot.params.code);
  }

}
