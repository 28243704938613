import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IRegionDto } from '../api-model/region-dto';
import { RegionsDataService } from './regions-data.service';

@Injectable({
  providedIn: 'root'
})
export class RegionsResolver implements Resolve<IRegionDto[]> {

  public constructor(
    private readonly regionsDataService: RegionsDataService
  ) { }

  // noinspection JSUnusedLocalSymbols
  public async resolve(): Promise<IRegionDto[]> {
    return this.regionsDataService.get();
  }

}
