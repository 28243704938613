// noinspection HttpUrlsUsage

export class AppClaimTypes {

  public static readonly username: string = 'http://aimplatform.io/username';
  public static readonly fullName: string = 'http://aimplatform.io/fullName';
  public static readonly givenName: string = 'http://aimplatform.io/givenName';
  public static readonly familyName: string = 'http://aimplatform.io/familyName';
  public static readonly nickname: string = 'http://aimplatform.io/nickname';
  public static readonly email: string = 'http://aimplatform.io/email';
  public static readonly emailVerified: string = 'http://aimplatform.io/emailVerified';
  public static readonly internalUser: string = 'http://aimplatform.io/internalUser';
  public static readonly anonymisedDataOnly: string = 'http://aimplatform.io/anon';
  public static readonly advertiserIds: string = 'http://aimplatform.io/ad/advertiserIds';
  public static readonly activeAdvertiserId: string = 'http://aimplatform.io/activeAdvertiserId';


  public static all = [
    AppClaimTypes.username,
    AppClaimTypes.fullName,
    AppClaimTypes.givenName,
    AppClaimTypes.familyName,
    AppClaimTypes.nickname,
    AppClaimTypes.email,
    AppClaimTypes.emailVerified,
    AppClaimTypes.internalUser,
    AppClaimTypes.anonymisedDataOnly,
    AppClaimTypes.advertiserIds,
    AppClaimTypes.activeAdvertiserId
  ];

}
