import { Injectable } from '@angular/core';

/**
 * Provides utility functionality related to the user's system-wide clipboard
 */
@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  /**
   * Copies arbitrary text to the clipboard
   * @param value The value to be copied to the clipboard. This value will first be converted to a string
   * using toString(), with null and undefined being converted to empty string.
   */
  public copyToClipboard(value: any) {
    navigator.clipboard.writeText(value?.toString() ?? '');
  }
}
