import { PercentPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({ name: 'neutralPercentageDifferenceFrom' })
export class NeutralPercentageDifferenceFromPipe implements PipeTransform {

  public constructor(private readonly percentPipe: PercentPipe) {}

  public transform(value: number, valueFrom?: number, digitsInfo = '1.0-1'): string {
    if (!value || !valueFrom) { return ''; }
    const change = (value / valueFrom) - 1;
    return `<em class="percentage-change">${change > 0 ? ' +' : ''}${this.percentPipe.transform(change, digitsInfo)}</em>`;
  }

}
