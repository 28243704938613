import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-short-number-with-tooltip',
  templateUrl: './short-number-with-tooltip.component.html',
  styleUrls: ['./short-number-with-tooltip.component.scss']
})
export class ShortNumberWithTooltipComponent {

  @Input() value: string | number;

  @Input() tooltipDigitsInfo = '1.0-0';

}
