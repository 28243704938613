import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

/**
 * Disables this interceptor for a given HTTP request
 * Usage: this.http.get(`${environment.apiBase}someEndpoint`, { headers: new HttpHeaders().set(SkipAuthorizationHeader, '') })
 */
export const SkipRateLimitingClientHeader = 'X-Skip-Rate-Limiting-Client-Header';

@Injectable()
export class RateLimitingClientInterceptor implements HttpInterceptor {

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Abort if the request has instructed us via a custom header to do so
    if (req.headers.has(SkipRateLimitingClientHeader)) {
      // Delete the temporary header otherwise ASP.NET might get spooked by the unrecognised header
      const headers = req.headers.delete(SkipRateLimitingClientHeader);
      return next.handle(req.clone({ headers }));
    }
    const modifiedReq = req.clone({ headers: req.headers.set('X-ClientId', environment.rateLimitingClientId) });
    return next.handle(modifiedReq);
  }
}
