import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { remove, uniq } from 'lodash-es';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StyleHelperService {

  private readonly adhocPageClasses: string[] = [];

  private _routePageClass: string;

  public constructor(private readonly router: Router) {
    // Subscribe to router activations and grab the page class from the route data
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0)
    ).subscribe((event: ActivationEnd) => {
      this._routePageClass = event.snapshot.data?.pageClass;
    });
  }

  public get pageClass() {
    return uniq([this._routePageClass].concat(this.adhocPageClasses).filter(x => x)).sort().join(' ');
  }

  public addPageClass(cls: string) {
    this.adhocPageClasses.push(cls);
  }

  public removePageClass(cls: string) {
    remove(this.adhocPageClasses, x => x === cls);
  }

}
