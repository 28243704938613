import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  public constructor(private readonly decimalPipe: DecimalPipe) { }

  public transform(value: string | number): string {

    if (typeof value === 'string') { value = parseFloat(value); }
    if (!value) { value = 0; }

    const abs = Math.abs(value);
    let letter = '';
    if (abs > 1000000000000000) {
      value /= 1000000000000000;
      letter = 'Q';
    } else if (abs > 1000000000000) {
      value /= 1000000000000;
      letter = 'T';
    } else if (abs > 1000000000) {
      value /= 1000000000;
      letter = 'B';
    } else if (abs > 1000000) {
      value /= 1000000;
      letter = 'M';
    } else if (abs > 1000) {
      value /= 1000;
      letter = 'K';
    }

    return `${this.decimalPipe.transform(value, `1.0-${value < 10 && letter ? '1' : '0'}`)}${letter}`;
  }
}
