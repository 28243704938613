import { Component } from '@angular/core';
import { orderBy } from 'lodash-es';
import { IUserOrganisationDto } from 'src/app/api-model/user-organisation-dto';
import { ThemeService } from '../services/theme.service';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent {

  public constructor(
    private readonly auth: AuthService,
    private readonly theme: ThemeService
  ) { }

  public get profile() {
    return this.auth.profile;
  }

  public get authenticated() {
    return this.auth.authenticated;
  }

  public get name() {
    return this.auth.profile.name;
  }

  public get dark() { return this.theme.dark; }

  public set dark(value: boolean) { this.theme.dark = value; }

  public get switchToAdvertisers(): IUserOrganisationDto[] {
    return orderBy(this.profile?.otherAdvertisers, [x => x.subscriptionTier, x => x.displayName], ['desc', 'asc']);
  }

  public async switchAdvertiser(advertiser: IUserOrganisationDto) {
    await this.auth.switchAdvertiser(advertiser.id);
  }

  public logout() {
    this.auth.logout();
  }

  public login() {
    this.auth.login();
  }

}
