import { PercentPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({ name: 'neutralPercentageChangeFrom' })
export class NeutralPercentageChangeFromPipe implements PipeTransform {

  public constructor(private readonly percentPipe: PercentPipe) {}

  public transform(value: number, valueFrom?: number, digitsInfo = '1.0-1'): string {
    if (!value || !valueFrom) { return ''; }
    const change = (value / valueFrom) - 1;
    return `<em class="percentage-change">${change > 0 ? '<i class="fa-solid fa-caret-up"></i> +' : '<i class="fa-solid fa-caret-down"></i> '}${this.percentPipe.transform(change, digitsInfo)}</em>`;
  }

}
