import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { isDate } from 'ngx-bootstrap/chronos';

@Pipe({
  name: 'weekDateRange'
})
export class WeekDateRangePipe implements PipeTransform {

  public transform(value: string | Date | Moment, short = true): string {
    if (value && (typeof value === 'string' || isDate(value))) { value = moment.utc(value); }
    if (!(moment.isMoment(value) && value.isValid() && value.year() >= 1900)) { return 'Unknown Date'; }
    const month = short ? 'MMM' : 'MMMM';
    const end = value.clone().endOf('week').add(1, 'day');
    return value.month() === end.month()
      ? `${value.format('D')}-${end.format(`D ${month}`)}`
      : `${value.format(`D ${month}`)}-${end.format(`D ${month}`)}`;
  }

}
