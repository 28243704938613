<header class="no-horizontal-scroll" id="black">
  <div class="content">
    <div class="title">
      <img *ngIf="icon" [alt]="iconAlt" [src]="icon" class="icon" />
      <ng-content select=".title-before"></ng-content>
      <h2 [innerHTML]="formattedTitle">
        <ng-content select="span.title-super"></ng-content>
        <ng-content select="span.title-badge"></ng-content>
      </h2>
    </div>
    <ng-content select=".right-content"></ng-content>
  </div>
  <ng-content select="ol.tab-links"></ng-content>
</header>
