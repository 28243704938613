import { AfterViewChecked, Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/auth/auth.service';
import { Permissions } from '../shared/auth/permissions';
import { LastErrorService } from './last-error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, AfterViewChecked {

  public constructor(
    public title: Title,
    private readonly lastErrorService: LastErrorService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly renderer: Renderer2
  ) {
    title.setTitle('Error');
    if (!lastErrorService.lastError) {
      // Possibly the page got reloaded whilst on the error page. There's no point
      // showing an empty error page so just send the user back to the dashboard
      this.router.navigate(['/']);
    }
  }

  public get supportEmail() { return environment.supportEmail; }

  public get showDebugInfo() {
    return environment.showDebugInfo || this.auth.userHasScope(Permissions.debug);
  }

  public get lastError() {
    return this.lastErrorService.lastError;
  }

  public get canReload() {
    return this.router.url !== '/error';
  }

  public ngAfterViewChecked(): void {
    this.lastErrorService.lastErrorPendingDisplay = false;
  }

  public ngOnInit() {
    // Remove body scrolling disabled behaviour in case it was left open by an error in a modal or blade
    if (document && document.body) { this.renderer.removeClass(document.body, 'modal-open'); }
  }

  public reload() {
    window.location.reload();
  }

  public dashboard() {
    window.location.replace('/');
  }

}
