import { Component } from '@angular/core';

/**
 * This component provides the endpoint for auth0 to return to after authentication.
 * Upon successful authentication the user will be redirected, so the user should
 * never see the content of this page.
 */
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent {}
