import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import Highcharts from 'highcharts';
import { environment } from '../environments/environment';
import { defaultChartOptions } from './default-chart-options';
import { AuthService } from './shared/auth/auth.service';
import { StyleHelperService } from './shared/services/style-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isOnline = true;

  public constructor(
    public title: Title,
    public auth: AuthService,
    private readonly styleHelper: StyleHelperService,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {

    // Configure Highcharts options
    Highcharts.setOptions(defaultChartOptions);

    this.isOnline = navigator.onLine;
    this.title.setTitle(`${environment.companyName}: ${environment.productName}`);
    angulartics2GoogleTagManager.startTracking();
    auth.initialize();
  }

  public get showLoadingIndicator() {
    // May need to change this if using modal dialogs
    return true;
  }

  public get disableInput() {
    // May need to change this if using modal dialogs
    return true;
  }

  public get pageClass() {
    return this.styleHelper.pageClass;
  }

  public async ngOnInit() {
    await this.auth.handleAuthCallback();
  }

}
