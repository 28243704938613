import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

/**
 * Disables this interceptor for a given HTTP request
 * Usage: this.http.get(`${environment.apiBase}someEndpoint`, { headers: new HttpHeaders().set(SkipAuthorizationHeader, '') })
 */
export const SkipAuthorizationHeader = 'X-Skip-Authorization-Header';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private readonly refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public constructor(
    private readonly auth: AuthService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Abort if the request has instructed us via a custom header to do so
    if (req.headers.has(SkipAuthorizationHeader)) {
      // Delete the temporary header otherwise ASP.NET might get spooked by the unrecognised header
      const headers = req.headers.delete(SkipAuthorizationHeader);
      return next.handle(req.clone({ headers }));
    }

    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401 && this.auth.canAttemptTokenRenewal) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready, and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(req)))
            );
          } else {
            this.refreshTokenInProgress = true;
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            return this.refreshAccessToken().pipe(
              switchMap((success: boolean) => {
                this.refreshTokenSubject.next(success);
                return next.handle(this.addAuthenticationToken(req));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => this.refreshTokenInProgress = false)
            );
          }
        } else {
          throw error;
        }
      })
    );
  }

  private refreshAccessToken(): Observable<any> {
    return from(this.auth.renewTokens());
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {

    // If we do not have a token yet, then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!this.auth.accessToken) {
      return request;
    }

    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(environment.authDomainPattern)) {
      return request;
    }

    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${this.auth.accessToken}`)
    });
  }
}
