import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors } from '@angular/forms';
import { flatMap, intersection, orderBy, uniq, without } from 'lodash-es';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DashboardPageSettingsService } from './analysis/shared/dashboard-page-settings.service';
import { IAttributionMetadataMetricDto } from './api-model/attribution-metadata-metric-dto';
import { FormHelperService } from './shared/services/form-helper.service';

@Component({
  selector: 'app-dashboard-page-settings',
  templateUrl: './dashboard-page-settings.component.html',
  styleUrls: ['./dashboard-page-settings.component.scss']
})
export class DashboardPageSettingsComponent implements OnDestroy {

  @ViewChild('pageSettings', { static: false }) public template: TemplateRef<any>;

  @Input() public appId: string;

  public modal: BsModalRef;

  public form = this.fb.group({
    metrics: new FormControl<string[] | null>([], [(control: AbstractControl): ValidationErrors | null =>
      control.value?.length < 1
        ? { custom: 'You must select at one metric' }
        : control.value?.length > 10
          ? { custom: 'The maximum number of metrics permitted per app is 10' }
          : null
    ])
  });

  public saving = false;
  public availableMetrics: IAttributionMetadataMetricDto[];

  public constructor(
    private readonly modalService: BsModalService,
    private readonly pageSettingsService: DashboardPageSettingsService,
    private readonly formHelper: FormHelperService,
    private readonly fb: FormBuilder
  ) {
    this.initializeForm();
  }

  public ngOnDestroy() {
    try { this.modal?.hide(); } catch { /* Doesn't matter if this fails */ }
  }

  public toggleMetric(name: string) {
    if (this.form.value.metrics.includes(name)) {
      this.form.controls.metrics.setValue(without(this.form.value.metrics, name));
    } else {
      this.form.controls.metrics.setValue(this.form.value.metrics.concat(name));
    }
  }

  public moveMetricToTop(name: string, event: MouseEvent) {
    event.stopPropagation();
    const oldIndex = this.availableMetrics.findIndex(x => x.name === name);
    this.availableMetrics = flatMap([
      this.availableMetrics[oldIndex],
      this.availableMetrics.slice(0, oldIndex),
      this.availableMetrics.slice(oldIndex + 1)]);
  }

  public async show() {
    const availableMetrics = await this.pageSettingsService.getAvailableMetricsByAppId(this.appId);
    const validMetricNames = availableMetrics.map(x => x.name);
    this.form.controls.metrics.setValue(intersection((await this.pageSettingsService.getByAppId(this.appId))?.metrics, validMetricNames) || []);
    this.form.controls.metrics.markAsDirty();
    this.availableMetrics = orderBy(availableMetrics, x => this.form.controls.metrics.value.includes(x.name) ? this.form.controls.metrics.value.findIndex(m => x.name === m) : 99999);
    this.modal = this.modalService.show(this.template, {
      keyboard: true,
      class: 'modal-md modal-dialog-centered modal-page-settings dashboard'
    });
  }

  public async submit() {
    if (!this.form.valid || this.saving) {
      this.formHelper.markAllAsDirty(this.form);
      return;
    }
    this.saving = true;
    try {
      this.pageSettingsService.update(this.appId, {
        metrics: uniq(orderBy(this.form.value.metrics, x => this.availableMetrics.findIndex(a => a.name === x)))
      });
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  public async resetByAppId() {
    this.saving = true;
    try {
      this.pageSettingsService.resetByAppId(this.appId);
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  public async resetAll() {
    this.saving = true;
    try {
      this.pageSettingsService.resetAll();
    } finally {
      this.saving = false;
      this.modal.hide();
    }
  }

  private initializeForm() {
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.saving = false;
  }

}
