import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timespan' })
export class TimespanPipe implements PipeTransform {

  public transform(value?: number, resolution: 'minutes' | 'seconds' = 'seconds'): string {

    if (resolution === 'minutes') {
      if (!value) { return '0m'; }
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      return [hours ? `${hours}h` : '', `${minutes}m`].filter(x => x).join(' ');
    }

    if (resolution === 'seconds') {
      if (!value) { return '0s'; }
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor(value / 60) - (hours * 60);
      const seconds = value % 60;
      return [hours ? `${hours}h` : '', hours || minutes ? `${minutes}m` : '', `${seconds}s`].filter(x => x).join(' ');
    }

    return '';
  }

}
