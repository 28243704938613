<div class="alert alert-warning">
  <p>
    <i class="fa-solid fa-triangle-exclamation"></i>
    <span>
    <strong>Unconfigured networks detected.</strong>
    Data has been found for the following networks which are currently not configured for any of your apps:
    </span>
  </p>
  <ng-container *ngIf="showApps">
    <hr />
    <div class="apps">
      <ng-container *ngFor="let app of missingNetworkIdsByApp" class="app">
        <div class="app">
          <img alt="{{app.app?.displayName}}" src="{{app.app | appIconUrlSmall}}" />
          <span>{{app.app?.displayName || app.app?.id}}</span>
        </div>
        <ul class="networks">
          <li *ngFor="let networkId of app.networkIds" class="network">
            <img alt="{{networks[networkId]?.displayName}}" src="{{networks[networkId]?.iconUrlSmall || '/assets/images/default-network-icon.svg'}}" />
            <span>{{networks[networkId]?.displayName || networkId}}</span>
          </li>
        </ul>
      </ng-container>
    </div>
    <hr />
  </ng-container>
  <ul *ngIf="!showApps" class="networks">
    <li *ngFor="let networkId of networkIds" class="network">
      <img alt="{{networks[networkId]?.displayName}}" src="{{networks[networkId]?.iconUrlSmall || '/assets/images/default-network-icon.svg'}}" />
      <span>{{networks[networkId]?.displayName || networkId}}</span>
    </li>
  </ul>
  <p>This may cause unexpected side effects or missing data in visualisations. It is strongly recommended that you configure these networks for use on your applications.</p>
  <hr />
  <button class="btn btn-primary" routerLink="/configuration/apps">Go to App Configuration</button>
</div>
