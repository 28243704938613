import { Component, ViewChild } from '@angular/core';
import { IKnowledgeBaseArticleDto } from '../api-model/knowledge-base-article';
import { BladeComponent } from './layout/blade.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { HelpDataService } from './services/help-data.service';

@Component({
  selector: 'app-help-blade',
  templateUrl: './help-blade.component.html',
  styleUrls: ['./help-blade.component.scss']
})
export class HelpBladeComponent {

  @ViewChild(BladeComponent, { static: false }) public blade: BladeComponent;

  public article: IKnowledgeBaseArticleDto;

  public constructor(
    private readonly helpDataService: HelpDataService,
    private readonly timeAgo: TimeAgoPipe
  ) { }

  public get subTitle() {
    return this.article?.editedDate ? `Updated ${this.timeAgo.transform(this.article.editedDate)}` : '';
  }

  public async open(articleId: string) {
    this.blade?.open();
    this.article = undefined;
    this.article = await this.helpDataService.get(articleId);
  }

  public close() { this.blade?.close(); }

}
