import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as StackTrace from 'stacktrace-js';
import { AuthService } from '../shared/auth/auth.service';
import { LoggingService } from '../shared/services/logging.service';
import { IErrorDetails } from './error-details';
import { GraphQLResponseError } from './graphql-response-error';
import { LastErrorService } from './last-error.service';

/**
 * Implements the ErrorHandler interface from angular to hook into all
 * unhandled runtime exceptions in the app. Implementation based on:
 * https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  private readonly stackTraceInMessageRegex = /^Error:.*[\r\n]+ {2,}[\s\S]*$/img;

  // Use injector because ErrorHandler gets instantiated very early, before most providers have been registered
  public constructor(
    private readonly injector: Injector,
    private readonly logger: LoggingService,
    private readonly lastErrorService: LastErrorService
  ) { }

  private get router(): Router {
    return this.injector.get(Router);
  }

  private get zone(): NgZone {
    return this.injector.get(NgZone);
  }

  /**
   * Handles a runtime error
   * @param error The error
   * @param show If true, the user will be redirected to the error page to view the error
   * @param log If true, the error will be sent to the error logging API
   */
  public async handleError(error: Error, show = true, log = true) {
    // Run inside a mutex to prevent multiple errors trying to be shown in quick succession.
    // NOTE: We can't store the mutex inside the error handler service because if an error occurs early enough in the pipeline we actually end up spawning two unrelated
    // copies of ErrorHandlerService, each with their own mutex
    await this.lastErrorService.mutex.runExclusive(async () => {
      // Abort if no error or if we're already showing the error page
      if (!error || this.router.url === '/error' || this.lastErrorService.lastErrorPendingDisplay) { return; }

      // Chunk load errors are caused by stale browser cache trying to load a JS file that no longer exists. Refreshing the page should clear it.
      if (/Loading chunk .*? failed/.test(error?.message || '')) {
        window.location.reload();
        return;
      }

      // Get the stack trace and process the error
      try {
        await this.zone.run(async () => {

          let stackString: string;
          try {
            stackString = (await StackTrace.fromError(error))
              .map(x => x.toString())
              .join('\n');
          } catch (e) {
            stackString = '';
          }

          let anyError = error as any;

          // Unwrap uncaught rejected promise errors
          if (anyError.rejection) { anyError = anyError.rejection; }

          const errorDetails = {
            message: error.message ? error.message : error.toString(),
            url: this.router.url
          } as IErrorDetails;

          // Grab the stack trace from the various fields that might contain it
          const originalStack = anyError.originalStack || '';
          errorDetails.originalStackTrace = originalStack || error.stack || '';
          errorDetails.parsedStackTrace = stackString || (originalStack ? error.stack : anyError.zoneAwareStack) || '';

          // If we got no stack trace from the error but found it in the message, use the one from the message
          if (!errorDetails.originalStackTrace && this.stackTraceInMessageRegex.test(errorDetails.message)) {
            errorDetails.originalStackTrace = errorDetails.message.replace(this.stackTraceInMessageRegex, '$0');
          }

          // Strip stack trace from message, if found
          errorDetails.message = errorDetails.message.replace(this.stackTraceInMessageRegex, '');

          // For file download errors, the error response arrives in the form of a Blob containing JSON so unpack it
          if (anyError?.error instanceof Blob) {
            try { anyError.error = JSON.parse(await (anyError.error as Blob).text()); } catch { /**/ }
          }

          // Grab details returned from API
          if (anyError?.error?.url) { errorDetails.url = anyError.error.url; }
          if (anyError?.error?.detail) { errorDetails.message = anyError.error.detail; }
          if (!errorDetails.originalStackTrace && anyError?.error?.stackTrace) { errorDetails.originalStackTrace = anyError.error.stackTrace; }

          // Parse ProblemDetailsDto if that's the kind of error we've received
          if (anyError?.detail) { errorDetails.message = anyError.detail; }
          if (anyError?.stackTrace) { errorDetails.originalStackTrace = anyError.stackTrace; }
          if (anyError?.status) { errorDetails.httpStatusCode = anyError.status; }

          // Capture GraphQL response errors
          if (anyError instanceof GraphQLResponseError) {
            errorDetails.graphqlErrors = (anyError as GraphQLResponseError)?.errors;
            errorDetails.graphqlRequest = (anyError as GraphQLResponseError)?.request;
          }

          try {
            // If the error is an angular HttpErrorResponse, grab additional details from it
            if (anyError?.constructor?.name === 'HttpErrorResponse') {
              const httpResponseError = (anyError as HttpErrorResponse);
              errorDetails.httpErrorUrl = httpResponseError.url;
              errorDetails.httpStatusCode = httpResponseError.status;
              errorDetails.httpStatusText = httpResponseError.statusText;
              errorDetails.httpError = JSON.stringify(httpResponseError.error, null, 2);
            }
          } catch (e) {
            console.log(`Error attempting to parse error as HttpErrorResponse: ${e.message}`);
          }

          try {
            const auth = this.injector.get(AuthService);
            errorDetails.userId = auth.profile.sub;
            errorDetails.userName = auth.profile.name;
            errorDetails.userEmail = auth.profile.email;
            errorDetails.userOrganisation = auth.profile.activeAdvertiser?.displayName;
          } catch (e) {
            console.log(`Error augmenting error details auth user identity: ${e.message}`);
          }

          // Record the last error so that it can be displayed on the error page.
          // See LastErrorService for an explanation of why this has to be done with a service.
          this.lastErrorService.lastError = errorDetails;

          // Log details on the server
          if (log) {
            this.logger.error(errorDetails);
          } else {
            console.log('The previous error will not be sent to the server because server-side logging was explicitly prevented');
          }

          let locationChangeOnError = false;
          try {
            locationChangeOnError = !!this.router.routerState.root.firstChild.snapshot.data.locationChangeOnError;
          } catch {}

          // Display message to the user via a new page
          if (show && this.router.url !== '/error') {
            await this.router.navigate(['/error'], { skipLocationChange: !locationChangeOnError });
            this.lastErrorService.lastErrorPendingDisplay = true;
          }
        });
      } catch (e) {
        // Show, but swallow errors in the error handler, otherwise we end up in an infinite loop
        console.error('Error in ErrorHandler');
        console.error(e);
      }
    });
  }

}
