<app-page-header [title]="title.getTitle()"></app-page-header>
<section class="page-area">
  <div class="padded-content top-bottom-padding">
    <app-content-header title="Something went wrong"></app-content-header>
    <p>We've logged the error and our development team will investigate if the problem continues.</p>
    <p>If this error is causing you a problem, please get in touch with our support team at <a
      class="clickable" href="mailto:{{supportEmail}}">{{supportEmail}}</a>.</p>
    <p class="actions">
      <button (click)="reload()" *ngIf="canReload" class="btn btn-primary">Reload this page</button>
      <span *ngIf="canReload">or</span>
      <a class="btn btn-primary" href="/" onclick="localStorage.removeItem('app.analysis.settings');">Return to the dashboard</a>
    </p>
    <div *ngIf="showDebugInfo && lastError" class="debug-info">
      <h3 *ngIf="lastError?.message">Message</h3>
      <pre *ngIf="lastError?.message">{{lastError?.message}}</pre>
      <h3 *ngIf="lastError?.url">Source Url</h3>
      <code *ngIf="lastError?.url">{{lastError?.url}}</code>
      <h3 *ngIf="lastError?.httpError">HTTP Error</h3>
      <pre *ngIf="lastError?.httpError">{{lastError?.httpError | decodeWhitespace}}</pre>
      <h3 *ngIf="lastError?.httpErrorUrl">HTTP Error Url</h3>
      <pre *ngIf="lastError?.httpErrorUrl">{{lastError?.httpErrorUrl}}</pre>
      <h3 *ngIf="lastError?.httpStatusCode">HTTP Status Code</h3>
      <pre *ngIf="lastError?.httpStatusCode">{{lastError?.httpStatusCode}} ({{lastError?.httpStatusText || 'Unknown'}})</pre>
      <h3 *ngIf="lastError?.parsedStackTrace">Parsed Stack Trace</h3>
      <pre *ngIf="lastError?.parsedStackTrace">{{lastError?.parsedStackTrace}}</pre>
      <h3 *ngIf="lastError?.originalStackTrace">Original Stack Trace</h3>
      <pre *ngIf="lastError?.originalStackTrace">{{lastError?.originalStackTrace}}</pre>
      <h3 *ngIf="lastError?.graphqlErrors">GraphQL Errors</h3>
      <pre *ngIf="lastError?.graphqlErrors">{{lastError?.graphqlErrors | json}}</pre>
      <h3 *ngIf="lastError?.graphqlRequest">GraphQL Request</h3>
      <h4 *ngIf="lastError?.graphqlRequest?.operationName">Operation name</h4>
      <code *ngIf="lastError?.graphqlRequest?.operationName">{{lastError?.graphqlRequest?.operationName}}</code>
      <h4 *ngIf="lastError?.graphqlRequest?.variables">Variables</h4>
      <pre *ngIf="lastError?.graphqlRequest?.variables">{{lastError?.graphqlRequest?.operationName}}</pre>
      <h4 *ngIf="lastError?.graphqlRequest?.query">Query</h4>
      <pre *ngIf="lastError?.graphqlRequest?.query">{{lastError?.graphqlRequest?.query}}</pre>
    </div>
  </div>
</section>
