import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AudienceService } from '../auth/audience.service';
import { AuthService } from '../auth/auth.service';
import { Permissions } from '../auth/permissions';
import { StyleHelperService } from '../services/style-helper.service';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.scss']
})
export class LeftnavComponent {

  public menuExpanded = false;

  public constructor(
    public readonly auth: AuthService,
    public readonly audience: AudienceService,
    private readonly styleHelper: StyleHelperService
  ) { }

  public get Permissions() { return Permissions; }

  public get showConfiguration() {
    return this.auth.profile?.activeAdvertiserId && this.auth.userHasScope(Permissions.configuration);
  }

  public get showAdmin() { return this.auth.profile?.activeAdvertiserId && this.auth.userHasScope(Permissions.admin); }

  public get version() { return environment.version === 'BUILD_VERSION' ? 'DEV' : `v${environment.version}`; }

  public toggleMenu() {
    this.menuExpanded = !this.menuExpanded;
    if (this.menuExpanded) {
      this.styleHelper.addPageClass('leftnav-expanded');
    } else {
      this.styleHelper.removePageClass('leftnav-expanded');
    }
  }

}
