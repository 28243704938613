import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWord'
})
export class NumberWordPipe implements PipeTransform {

  public constructor(private readonly decimalPipe: DecimalPipe) { }

  public transform(value: string | number, decimals: number = 1): string {

    if (typeof value === 'string') { value = parseFloat(value); }
    if (!value) { value = 0; }

    const abs = Math.abs(value);
    let word = '';
    if (abs > 1000000000000000) {
      value /= 1000000000000000;
      word = 'quadrillion';
    } else if (abs > 1000000000000) {
      value /= 1000000000000;
      word = 'trillion';
    } else if (abs > 1000000000) {
      value /= 1000000000;
      word = 'billion';
    } else if (abs > 1000000) {
      value /= 1000000;
      word = 'million';
    } else if (abs > 1000) {
      value /= 1000;
      word = 'thousand';
    }

    return `${this.decimalPipe.transform(value, `1.0-${value > 10 && word ? decimals.toString() : '0'}`)} ${word}`;
  }
}
