import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

/**
 * Guards access to a route by testing that the user is authenticated and that they have at least one
 * organisation associated with their account. If they are not authenticated it will force them to log in,
 * if they don't have at least one organisation associated with their account it will redirect them to
 * NoActiveOrganisationComponent
 */
@Injectable({
  providedIn: 'root'
})
export class ValidAccountGuard implements CanActivate, CanActivateChild, CanLoad {

  public constructor(private readonly auth: AuthService, public router: Router) { }

  // noinspection JSUnusedLocalSymbols
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard();
  }

  // noinspection JSUnusedLocalSymbols
  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guard();
  }

  // noinspection JSUnusedLocalSymbols
  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard();
  }

  public async guard(skipLocationChange = false) {
    await this.auth.ensureLogin();
    if (!this.auth.profile.email_verified) {
      this.router.navigate(['/email-unverified'], { skipLocationChange });
      return false;
    } else if (!this.auth.profile.activeAdvertiserId) {
      this.router.navigate(['/no-active-organisation'], { skipLocationChange });
      return false;
    }
    return true;
  }

}
