import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { INetworkDto } from '../api-model/network-dto';
import { NetworksDataService } from './networks-data.service';

@Injectable({
  providedIn: 'root'
})
export class NetworksResolver implements Resolve<INetworkDto[]> {

  public constructor(private readonly networksDataService: NetworksDataService) { }

  // noinspection JSUnusedLocalSymbols
  public async resolve(): Promise<INetworkDto[]> {
    return this.networksDataService.get();
  }

}
