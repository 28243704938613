import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { assign, isError } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { IClientErrorDto } from 'src/app/api-model/client-error-dto';
import { environment } from '../../../environments/environment';

/* tslint:disable:no-console */

/**
 * Provides application-wide logging capabilities. This service should always be used in preference
 * over the browser's direct console API (except in short term debugging scenarios) because it can
 * send logs to the server and determine what to send to the browser's console based on environment context.
 */
@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  public constructor(
    private readonly http: HttpClient
  ) { }

  private get logLevel() { return environment.logLevel.toLowerCase(); }

  public error(message?: any, ...optionalParams: any[]): void {

    if (['error', 'warning', 'info', 'debug'].indexOf(this.logLevel) === -1) { return; }

    console.error.apply(null, arguments);

    if (!message) { return; }

    const errorDto = {
      message: '',
      url: '',
      stackTrace: ''
    } as IClientErrorDto;

    if (isError(message)) {
      errorDto.message = message.message || '';
      errorDto.url = window.location.href || '';
      const originalStack = (message as any).originalStack || '';
      errorDto.originalStackTrace = originalStack || message.stack || '';
      errorDto.parsedStackTrace = (originalStack ? message.stack : (message as any).zoneAwareStack) || '';
    } else if (message.originalStackTrace) { // Assume IErrorDetails
      assign(errorDto, message);
    } else if (typeof message === 'string') {
      errorDto.message = message || '';
      errorDto.url = window.location.href || '';
    } else {
      return;
    }

    // Use a simple HttpClient call in fire-and-forget fashion here - we don't need the behaviour of ApiService. Swallow errors.
    firstValueFrom(this.http.post(`${environment.apiBase}log/error`, errorDto)).catch(x => console.error(x));
  }

  // noinspection JSUnusedGlobalSymbols
  public warn(message?: any, ...optionalParams: any[]): void {
    if (['warning', 'info', 'debug'].indexOf(this.logLevel) === -1) { return; }
    console.warn.apply(null, arguments);
    const messages = (Array.prototype.slice.call(arguments) as string[]).map(x => x.toString()).filter(x => x);
    if (messages.length) {
      firstValueFrom(this.http.post(
        `${environment.apiBase}log/warning`,
        messages,
        // Use a magic string to avoid circular dependency since error handler uses the logger. Don't do this anywhere else.
        { headers: new HttpHeaders().set('X-Skip-Error-Handler', '') }
      )).catch(x => console.error(x));
    }
  }

  public info(message?: any, ...optionalParams: any[]): void {
    if (['info', 'debug'].indexOf(this.logLevel) === -1) { return; }
    console.info.apply(null, arguments);
    const messages = (Array.prototype.slice.call(arguments) as string[]).map(x => x.toString()).filter(x => x);
    if (messages.length) {
      firstValueFrom(this.http.post(
        `${environment.apiBase}log/info`,
        messages,
        // Use a magic string to avoid circular dependency since error handler uses the logger. Don't do this anywhere else.
        { headers: new HttpHeaders().set('X-Skip-Error-Handler', '') }
      )).catch(x => console.error(x));
    }
  }

  public debug(message?: any, ...optionalParams: any[]): void {
    if (['debug'].indexOf(this.logLevel) === -1) { return; }
    console.debug.apply(null, arguments);
    const messages = (Array.prototype.slice.call(arguments) as string[]).map(x => x.toString()).filter(x => x);
    if (messages.length) {
      firstValueFrom(this.http.post(
        `${environment.apiBase}log/debug`,
        messages,
        // Use a magic string to avoid circular dependency since error handler uses the logger. Don't do this anywhere else.
        { headers: new HttpHeaders().set('X-Skip-Error-Handler', '') }
      )).catch(x => console.error(x));
    }
  }

  public log(message?: any, ...optionalParams: any[]): void {
    if (['info', 'debug'].indexOf(this.logLevel) === -1) { return; }
    console.log.apply(null, arguments);
    const messages = (Array.prototype.slice.call(arguments) as string[]).map(x => x.toString()).filter(x => x);
    if (messages.length) {
      firstValueFrom(this.http.post(
        `${environment.apiBase}log/info`,
        messages,
        // Use a magic string to avoid circular dependency since error handler uses the logger. Don't do this anywhere else.
        { headers: new HttpHeaders().set('X-Skip-Error-Handler', '') }
      )).catch(x => console.error(x));
    }
  }

}
