import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { first } from 'lodash-es';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  public constructor(public title: Title, private readonly route: ActivatedRoute) {
    this.title.setTitle('Page Not Found');
    console.log(first(route.snapshot.url)?.path);
  }

}
