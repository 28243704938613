import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { flatMap, isEqual } from 'lodash-es';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { IAppNetworkDto } from '../../api-model/app-network-dto';
import { Cadence, CadenceDesc, CadencePeriodPluralDesc } from '../../api-model/enums/cadence';
import { IRegionDto } from '../../api-model/region-dto';
import { AppNetworksDataService } from '../../app-networks/app-networks-data.service';
import { RegionsDataService } from '../../regions/regions-data.service';
import { EnumHelperService } from '../../shared/services/enum-helper.service';
import { AnalysisSettingsService } from './analysis-settings.service';
import { DateRange } from 'src/app/shared/date-range';

@Component({
  selector: 'app-analysis-settings',
  templateUrl: './analysis-settings.component.html',
  styleUrls: ['./analysis-settings.component.scss']
})
export class AnalysisSettingsComponent implements OnInit {

  public regions: IRegionDto[] = [];
  public networks: IAppNetworkDto[] = [];
  @Input() public showRegion = false;
  @Input() public showNetwork = false;
  @Input() public showCadence = true;
  @Input() public showSmoothing = true;
  @Input() public showDateRange = true;
  public readonly cadences: { label: string; value: number }[];
  public smoothingLevels: { label: string; value: number }[] = [];
  private appId: string;
  private regionsSubscription?: Subscription;
  private networksSubscription?: Subscription;
  private routeParamsSubscription?: Subscription;
  private settingsSubscription?: Subscription;

  public constructor(
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly regionsDataService: RegionsDataService,
    private readonly appNetworksDataService: AppNetworksDataService,
    private readonly route: ActivatedRoute,
    enumHelper: EnumHelperService
  ) {
    this.cadences = enumHelper.getDescriptionMap(Cadence, CadenceDesc);
  }

  private _filteredRegions: IRegionDto[];

  public get filteredRegions() {
    if (!this._filteredRegions) {
      const regionIds = flatMap(this.networks.map(x => x.regions.map(r => r.id)));
      this._filteredRegions = this.regions.filter(x => regionIds.includes(x.id));
    }

    return this._filteredRegions;
  }

  public get CadencePeriodPluralDesc() { return CadencePeriodPluralDesc; }

  public get regionId() { return this.analysisSettings.regionId; }

  public set regionId(value: string) { this.analysisSettings.regionId = value; }

  public get networkId() { return this.analysisSettings.networkId; }

  public set networkId(value: string) { this.analysisSettings.networkId = value; }

  public get cadence() { return this.analysisSettings.cadence; }

  public set cadence(value: Cadence) {
    this.analysisSettings.cadence = value;
    if (this.analysisSettings.isCustomDateRange) {
      this.analysisSettings.dateRange = DateRange.getValidCustomDateRange(this.analysisSettings.cadence, this.analysisSettings.dateRange);
    }
    else {
      this.analysisSettings.dateRange = DateRange.getDefaultDateRange(this.cadence);
    }
    this.reloadDateRangePicker();
  }

  public get smoothingLevel() { return this.analysisSettings.smoothingLevel; }

  public set smoothingLevel(value: number) { this.analysisSettings.smoothingLevel = value; }

  public get dateRange() { return this.analysisSettings.dateRange; }

  public set dateRange(value: DateRange) {
    this.analysisSettings.dateRange = value;
  }

  public get namedRanges() { return DateRange.getNamedDateRanges(this.analysisSettings.cadence); }

  public get minDate() { return DateRange.getMinDate(this.analysisSettings.cadence); }

  public get maxSpan() { return DateRange.getMaxSpan(this.analysisSettings.cadence); }

  public ngOnInit() {
    this.regionsSubscription = this.regionsDataService.regions$.subscribe(x => {
      this.regions = x || [];
      this._filteredRegions = undefined;
    });

    this.routeParamsSubscription = this.route.params.pipe(distinctUntilChanged((a, b) => isEqual(a.appId, b.appId))).subscribe(async p => {
      this.appId = p.appId;
      this.setupNetworksSubscription();
    });

    this.setupNetworksSubscription();

    this.settingsSubscription = this.analysisSettings.settings$.pipe(distinctUntilChanged((x, y) => isEqual(x.cadence, y.cadence)))
      .subscribe(async settings => {
        this.smoothingLevels = settings?.smoothingPeriods[settings.cadence]?.map((periodLength, i) => ({
          label: periodLength ? `${periodLength} ${CadencePeriodPluralDesc[settings.cadence]}` : 'Off',
          value: i
        })) || [];
      });
  }

  public ngOnDestroy() {
    this.regionsSubscription?.unsubscribe();
    this.networksSubscription?.unsubscribe();
    this.routeParamsSubscription?.unsubscribe();
    this.settingsSubscription?.unsubscribe();
  }

  private setupNetworksSubscription() {
    this.networksSubscription?.unsubscribe();
    if (!this.showNetwork && !this.showRegion) { return; }
    this.networksSubscription = this.appNetworksDataService.appNetworks$(this.appId).subscribe(x => {
      this.networks = x?.filter(n => n.active && n.enabled) || [];
      this._filteredRegions = undefined;
    });
  }

  private reloadDateRangePicker() {
    if (!this.showDateRange) return;
    setTimeout(() => {
      this.showDateRange = false;
      this.showDateRange = true;
    }, 0);
  }

  public dateRangeChanged(presetName: string) {
    this.analysisSettings.isCustomDateRange = !presetName;
    if (this.analysisSettings.isCustomDateRange) {
      this.analysisSettings.dateRange = DateRange.getValidCustomDateRange(this.analysisSettings.cadence, this.analysisSettings.dateRange);
      this.reloadDateRangePicker();
    }
  }
}
