import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEqual, isUndefined } from 'lodash-es';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IHistoryDto } from '../api-model/history-dto';
import { INetworkDto } from '../api-model/network-dto';
import { SkipModelStateError } from '../errors/error.interceptor';
import { AudienceService } from '../shared/auth/audience.service';
import { AuthService } from '../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NetworksDataService {

  private _networksLoading?: Promise<INetworkDto[]>;
  private _allNetworksLoading?: Promise<INetworkDto[]>;

  public constructor(
    private readonly http: HttpClient,
    private readonly audience: AudienceService,
    private readonly auth: AuthService
  ) {
    // Update whenever the user's active advertiser changes
    auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(() => this.invalidate());
  }

  private _networks$: BehaviorSubject<INetworkDto[]>;

  public get networks$() {
    if (!this._networks$) { this._networks$ = new BehaviorSubject<INetworkDto[]>(undefined); }
    if (!this._networks$.value) { this.get(); }
    return this._networks$.asObservable();
  }

  private _allNetworks$: BehaviorSubject<INetworkDto[]>;

  public get allNetworks$() {
    if (!this._allNetworks$) { this._allNetworks$ = new BehaviorSubject<INetworkDto[]>(undefined); }
    if (!this._allNetworks$.value) { this.getAll(); }
    return this._allNetworks$.asObservable();
  }

  public async get(ignoreCache = false): Promise<INetworkDto[]> {
    if (this._networksLoading) { return this._networksLoading; }
    if (!this._networks$) { this._networks$ = new BehaviorSubject<INetworkDto[]>(undefined); }
    if (!ignoreCache && !isUndefined(this._networks$.value)) { return this._networks$.value; }
    this._networksLoading = new Promise(async resolve => {
      const data = (await firstValueFrom(this.http.get<INetworkDto[]>(`${environment.apiBase}networks/in-use`))) || [];
      this._networks$.next(data);
      resolve(data);
      this._networksLoading = undefined;
    });
    return this._networksLoading;
  }

  public async getById(id: string, ignoreCache = false): Promise<INetworkDto> {
    return (await this.get(ignoreCache)).find(x => x.id === id) || (await this.getAll(ignoreCache)).find(x => x.id === id);
  }

  public async getAll(ignoreCache = false): Promise<INetworkDto[]> {
    if (this._allNetworksLoading) { return this._allNetworksLoading; }
    if (!this._allNetworks$) { this._allNetworks$ = new BehaviorSubject<INetworkDto[]>(undefined); }
    if (!ignoreCache && !isUndefined(this._allNetworks$.value)) { return this._allNetworks$.value; }
    this._allNetworksLoading = new Promise(async resolve => {
      const data = (await firstValueFrom(this.http.get<INetworkDto[]>(`${environment.apiBase}networks`))) || [];
      this._allNetworks$.next(data);
      resolve(data);
      this._allNetworksLoading = undefined;
    });
    return this._allNetworksLoading;
  }

  public async create(network: INetworkDto) {
    return firstValueFrom(this.http.post<INetworkDto>(`${environment.apiBase}networks`, network, {
      // Skip model state errors because we expect the consumer to handle them
      headers: new HttpHeaders().set(SkipModelStateError, '')
    })).then((x: INetworkDto) => {
      this.invalidate();
      return x;
    });
  }

  public async update(network: INetworkDto) {
    return firstValueFrom(this.http.patch<INetworkDto>(`${environment.apiBase}networks/${network.id}`, network, {
      // Skip model state errors because we expect the consumer to handle them
      headers: new HttpHeaders().set(SkipModelStateError, '')
    })).then((x: INetworkDto) => {
      this.invalidate();
      return x;
    });
  }

  public async delete(id: string): Promise<void> {
    await firstValueFrom(this.http.delete(`${environment.apiBase}networks/${id}`));
    this.invalidate();
  }

  public async getHistory(id: string): Promise<IHistoryDto[]> {
    return firstValueFrom(this.http.get<IHistoryDto[]>(`${environment.apiBase}networks/${id}/history`));
  }

  public invalidate() {
    // If we have no subscribers, just invalidate the observable so that the next subscription will cause the data to be re-requested - otherwise re-request the data
    if (!this._networks$?.observed) { this._networks$?.next(undefined); } else { this.get(true); }
    if (!this._allNetworks$?.observed) { this._allNetworks$?.next(undefined); } else { this.get(true); }
  }

}
