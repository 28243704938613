import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { environment } from '../../environments/environment';
import { Permissions } from '../shared/auth/permissions';
import { AuthService } from '../shared/auth/auth.service';

@Component({
  selector: 'app-resource-not-found',
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss']
})
export class ResourceNotFoundComponent {

  public resolver = '';

  public params: any = {};

  public constructor(public title: Title, private readonly route: ActivatedRoute, private readonly auth: AuthService) {
    this.title.setTitle('Resource Not Found');
    this.resolver = route.snapshot.queryParams.resolver || '';
    this.params = cloneDeep(route.snapshot.queryParams);
    delete this.params.resolver;
  }

  public get showDebugInfo() { return environment.showDebugInfo || this.auth.userHasScope(Permissions.debug); }

}
