import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IKnowledgeBaseArticleDto } from '../../api-model/knowledge-base-article';

@Injectable({
  providedIn: 'root'
})
export class HelpDataService {

  public constructor(
    private readonly http: HttpClient
  ) { }

  public async get(articleId: string): Promise<IKnowledgeBaseArticleDto> {
    return firstValueFrom(this.http.get<IKnowledgeBaseArticleDto>(`${environment.apiBase}knowledge-base/${articleId}`));
  }

}
