import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IUserOrganisationDto } from 'src/app/api-model/user-organisation-dto';
import { environment } from '../../../environments/environment';
import { AudienceService } from './audience.service';
import { AuthService } from './auth.service';
import { Permissions } from './permissions';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  public constructor(
    public title: Title,
    public auth: AuthService,
    public readonly audience: AudienceService
  ) {
    this.title.setTitle('User Profile');
  }

  public get profile() { return this.auth.profile; }

  public get switchToOrganisations() { return this.profile?.otherAdvertisers?.filter(x => x.active) || []; }

  public get idToken() { return this.auth.idToken; }

  public get accessToken() { return this.auth.accessToken; }

  public get scopes() { return this.auth.scopes; }

  public get expiresAt() { return this.auth.expiresAt; }

  public get canChangePassword() { return this.auth.canChangePassword; }

  public get showDebugInfo() { return environment.showDebugInfo || this.auth.userHasScope(Permissions.debug); }

  public async changePassword() { await this.auth.changePassword(); }

  public async switchActiveOrganisation(advertiser: IUserOrganisationDto) { await this.auth.switchAdvertiser(advertiser.id); }

  public logout() { this.auth.logout(); }

  public sendProfileToConsole() { console.log(this.profile); }

}
