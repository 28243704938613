import { Pipe, PipeTransform } from '@angular/core';
import { last } from 'lodash-es';

@Pipe({
  name: 'last'
})
export class LastPipe implements PipeTransform {

  public transform<T>(value: T[]): T {
    return last(value);
  }

}
