import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortCurrency'
})
export class ShortCurrencyPipe implements PipeTransform {

  public constructor(private readonly currencyPipe: CurrencyPipe) { }

  public transform(value: string | number, currencyCode: string = 'USD', maxDecimalPrecision = 1): string {

    if (typeof value === 'string') { value = parseFloat(value); }
    if (!value) { value = 0; }

    const abs = Math.abs(value);
    let letter = '';
    if (abs > 1000000000000000) {
      value /= 1000000000000000;
      letter = 'Q';
    } else if (abs >= 1000000000000) {
      value /= 1000000000000;
      letter = 'T';
    } else if (abs >= 1000000000) {
      value /= 1000000000;
      letter = 'B';
    } else if (abs >= 1000000) {
      value /= 1000000;
      letter = 'M';
    } else if (abs >= 1000) {
      value /= 1000;
      letter = 'K';
    }

    return `${this.currencyPipe.transform(value, currencyCode, 'symbol', `1.0-${value < 10 ? maxDecimalPrecision.toString() : '0'}`)}${letter}`;
  }

}
