import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocalStorageModule } from 'angular-2-local-storage';
import { Angulartics2Module } from 'angulartics2';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_more from 'highcharts/highcharts-more';
import * as highchartsSankey from 'highcharts/modules/sankey';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { provideEnvironmentNgxCurrency } from 'ngx-currency';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AnalysisSharedModule } from './analysis/shared/analysis-shared.module';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardPageSettingsComponent } from './dashboard-page-settings.component';
import { DashboardComponent } from './dashboard.component';
import { ErrorsModule } from './errors/errors-module';
import { ErrorsRoutingModule } from './errors/errors-routing.module';
import { RoutingHelperService } from './routing-helper.service';
import { AuthRoutingModule } from './shared/auth/auth-routing.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { SharedModule } from './shared/shared.module';

highchartsSankey.default(Highcharts);
HC_more(Highcharts);

// noinspection JSUnusedGlobalSymbols
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardPageSettingsComponent
  ],
  imports: [
    // Angular modules
    BrowserModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    BrowserAnimationsModule,

    // Angular Material
    DragDropModule,

    // NgxBootstrap modules
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),

    // Third party modules
    Angulartics2Module.forRoot(),
    LocalStorageModule.forRoot(),
    NgSelectModule,
    ToastrModule.forRoot(),
    HighchartsChartModule,
    NgxDropzoneModule,
    NgCircleProgressModule.forRoot({
      backgroundOpacity: 0,
      backgroundPadding: 0,
      radius: 16,
      space: -1,
      outerStrokeWidth: 1,
      outerStrokeColor: '#e77a35',
      innerStrokeColor: 'rgba(127, 127, 127, .3)',
      innerStrokeWidth: 2,
      titleFontSize: '10px',
      titleFontWeight: 'bold',
      unitsFontSize: '8px',
      animation: false,
      showSubtitle: false,
      showUnits: true,
      maxPercent: 100
    }),
    UiSwitchModule.forRoot({
      color: '#e77a35',
      size: 'small'
    }),

    // Routing modules. Load order is important here, because the router uses a first-match-wins strategy
    AppRoutingModule,
    AuthRoutingModule,
    ErrorsRoutingModule,

    // Our modules
    SharedModule.forRoot(),
    PipesModule.forRoot(),
    ErrorsModule.forRoot(),
    NgOptimizedImage,
    AnalysisSharedModule
  ],
  exports: [
    RouterModule
  ],
  bootstrap: [AppComponent],
  providers: [
    RoutingHelperService, {
      provide: APP_INITIALIZER,
      useFactory: (service: RoutingHelperService) => () => service.initialize(),
      multi: true,
      deps: [RoutingHelperService]
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          javascript: () => import('highlight.js/lib/languages/javascript'),
          shell: () => import('highlight.js/lib/languages/shell'),
          csharp: () => import('highlight.js/lib/languages/csharp'),
          go: () => import('highlight.js/lib/languages/go'),
          java: () => import('highlight.js/lib/languages/java'),
          php: () => import('highlight.js/lib/languages/php'),
          python: () => import('highlight.js/lib/languages/python'),
          ruby: () => import('highlight.js/lib/languages/ruby'),
          json: () => import('highlight.js/lib/languages/json')
        }
      }
    },
    provideEnvironmentNgxCurrency({
      align: 'left',
      allowNegative: false,
      allowZero: false,
      decimal: '.',
      precision: 0,
      prefix: '$',
      suffix: '',
      thousands: ',',
      nullable: true
    })
  ]
})
export class AppModule {}
