<div class="page-top-filters no-horizontal-scroll {{containerClass}}">
  <a (click)="toggleExpanded()" *ngIf="toggle && !expanded" class="expand" href="javascript:void 0;"><i class="fa fa-filter"></i> Filter<span *ngIf="hasExport"> &amp; Export</span></a>
  <div *ngIf="expanded" class="wrap-outer">
    <div class="wrap">
      <div class="left-content">
        <ng-content select=".filters"></ng-content>
      </div>
      <ng-content select=".right-content"></ng-content>
    </div>
    <ng-content select=".buttons"></ng-content>
  </div>
</div>
