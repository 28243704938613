import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  public transform(text: string = '', length = 0, suffix = '', wordBreak = true): string {
    if (!text) { return ''; }
    return text.length > length
      ? wordBreak
        ? text.slice(0, length) + suffix
        // tslint:disable-next-line:no-bitwise
        : !!~text.indexOf(' ', length)
          ? text.slice(0, text.indexOf(' ', length)) + suffix
          : text
      : text;
  }
}
