import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { flatMap, keyBy, uniq } from 'lodash-es';
import { Subscription } from 'rxjs';
import { IAppDto } from '../api-model/app-dto';
import { INetworkDto } from '../api-model/network-dto';
import { NetworksDataService } from '../networks/networks-data.service';

@Component({
  selector: 'app-unconfigured-networks',
  templateUrl: './unconfigured-networks.component.html',
  styleUrls: ['./unconfigured-networks.component.scss']
})
export class UnconfiguredNetworksComponent implements OnInit, OnDestroy {

  @Input() public showApps = false;

  @Input() public missingNetworkIdsByApp: { app: IAppDto; networkIds: string[]; }[] = [];
  public networks: { [id: string]: INetworkDto } = {};
  private networksSubscription: Subscription;

  public constructor(private readonly networksDataService: NetworksDataService) {}

  public get networkIds(): string[] {
    return uniq(flatMap(this.missingNetworkIdsByApp.map(x => x.networkIds)));
  }

  public ngOnInit(): void {
    this.networksSubscription = this.networksDataService.allNetworks$.subscribe(x => this.networks = keyBy(x, x => x.id) || {});
  }

  public ngOnDestroy(): void {
    this.networksSubscription?.unsubscribe();
  }

}
