<app-page-header [title]="title.getTitle()"></app-page-header>
<section class="page-area">
  <div class="padded-content top-bottom-padding">
    <app-content-header title="The requested page requires a resource that does not exist"></app-content-header>
    <div *ngIf="showDebugInfo" class="debug-info">
      <h3 *ngIf="resolver">Resolver</h3>
      <pre *ngIf="resolver">{{resolver}}</pre>
      <h3 *ngIf="params">Parameters</h3>
      <pre *ngIf="params">{{params | json}}</pre>
    </div>
    <a class="btn btn-primary" href="/">Return to the dashboard</a>
  </div>
</section>
