import { Pipe, PipeTransform } from '@angular/core';
import { first } from 'lodash-es';

@Pipe({
  name: 'first'
})
export class FirstPipe implements PipeTransform {

  public transform<T>(value: T[]): T {
    return first(value);
  }

}
