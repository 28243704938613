import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[goodBadClass]'
})
export class GoodBadClassDirective {

  public constructor(private readonly renderer: Renderer2, private readonly hostElement: ElementRef) {
    this.setClasses();
  }

  private _negativeIsGood: boolean;

  public get negativeIsGood() { return this._negativeIsGood; }

  @Input('negativeIsGood')
  public set negativeIsGood(v: boolean) {
    this._negativeIsGood = v;
    this.setClasses();
  }

  private _neutral: boolean;

  public get neutral() { return this._neutral; }

  @Input('neutral')
  public set neutral(v: boolean) {
    this._neutral = v;
    this.setClasses();
  }

  private _value: number;

  public get value() { return this._value; }

  @Input('goodBadClass')
  public set value(v: number) {
    this._value = v;
    this.setClasses();
  }

  private setClasses() {
    if (this.neutral) {
      this.renderer.removeClass(this.hostElement.nativeElement, 'good');
      this.renderer.removeClass(this.hostElement.nativeElement, 'bad');
      return;
    }

    const value = this._value || 0;
    if ((value > 0 && this.negativeIsGood) || (value < 0 && !this.negativeIsGood)) {
      this.renderer.removeClass(this.hostElement.nativeElement, 'good');
      this.renderer.addClass(this.hostElement.nativeElement, 'bad');
    } else if ((value < 0 && this.negativeIsGood) || (value > 0 && !this.negativeIsGood)) {
      this.renderer.removeClass(this.hostElement.nativeElement, 'bad');
      this.renderer.addClass(this.hostElement.nativeElement, 'good');
    } else {
      this.renderer.removeClass(this.hostElement.nativeElement, 'good');
      this.renderer.removeClass(this.hostElement.nativeElement, 'bad');
    }
  }

}
