import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  private _sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  public constructor(private readonly decimalPipe: DecimalPipe) { }

  public transform(value: number, digitsInfo = '1.0-1'): string {
    if (!value) { return '0 Bytes'; }
    const i = Math.floor(Math.log(value) / Math.log(1024));
    return `${this.decimalPipe.transform(value / Math.pow(1024, i), digitsInfo)} ${this._sizes[i]}`;
  }

}
