import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {

  public constructor() { super(); }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    return super.shouldReuseRoute(future, current) && current.data.noReuse !== true;
  }

}
