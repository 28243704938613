<app-page-header [title]="title.getTitle()"></app-page-header>
<section class="page-area">
  <div class="padded-content top-bottom-padding">
    <div class="profile">
      <div *ngIf="profile?.picture" class="avatar-wrap">
        <img alt="avatar" class="avatar" referrerpolicy="no-referrer" src="{{profile?.picture}}" />
      </div>
      <div class="detail">
        <h2>{{profile?.name}}</h2>
        <h3>Account Details</h3>
        <dl>
          <dt>Email:</dt>
          <dd class="nowrap">{{profile?.email || 'Not set'}} &nbsp;
            <span *ngIf="profile?.email_verified" class="badge badge-success"><i class="fa fa-check"></i>
              Verified</span>
          </dd>
          <dt>{{audience.name}}:</dt>
          <dd class="nowrap">
            <span>{{profile?.activeAdvertiser?.displayName || 'None'}}</span>
            <div *ngIf="profile?.otherAdvertisers?.length < 20">
              <div class="btn-group" dropdown>
                <button aria-controls="dropdown-basic" class="btn btn-primary dropdown-toggle" dropdownToggle id="button-basic"
                        type="button">
                  Switch <span class="caret"></span>
                </button>
                <ul *dropdownMenu aria-labelledby="button-basic" class="dropdown-menu" id="dropdown-basic" role="menu">
                  <li *ngFor="let organisation of switchToOrganisations" role="menuitem"><a (click)="switchActiveOrganisation(organisation)"
                                                                                            class="dropdown-item">{{organisation.displayName}}</a></li>
                </ul>
              </div>
            </div>
          </dd>
          <dt *ngIf="audience?.name">Account Type:</dt>
          <dd *ngIf="audience?.name" class="nowrap">
            <span>{{audience?.name}}</span>
            <span *ngIf="profile?.internalUser" class="badge badge-info">Internal user</span>
            <span *ngIf="profile?.anonymisedDataOnly" class="badge badge-info">Demo</span>
          </dd>
          <dt *ngIf="profile?.activeAdvertiserId">Active Organisation ID:</dt>
          <dd *ngIf="profile?.activeAdvertiserId" class="nowrap">
            <span>{{profile?.activeAdvertiserId}}</span>
            <app-copy-to-clipboard copyValue="{{profile.activeAdvertiserId}}" size="micro"></app-copy-to-clipboard>
          </dd>
        </dl>
        <div class="button-row">
          <button (click)="changePassword()" *ngIf="canChangePassword" class="btn btn-primary">Change Password</button>
          <button (click)="logout()" class="btn btn-secondary">Log out</button>
        </div>
        <div *ngIf="showDebugInfo" class="debug-info">
          <h3>Scopes</h3>
          <div class="scopes">
            <span *ngFor="let scope of scopes" class="badge badge-info">{{scope}}</span>
          </div>
          <h3>Full Profile</h3>
          <div class="button-row">
            <button (click)="sendProfileToConsole()" class="btn btn-secondary">Send to Console</button>
          </div>
          <h3>Access Token <span>(expires {{expiresAt | customDate:'DD MMMM YYYY HH:mm:ss'}})</span></h3>
          <code>{{accessToken}}</code>
          <div class="button-row">
            <a class="btn btn-secondary" href="https://jwt.io/?value={{accessToken}}" target="_blank">Inspect at
              jwt.io</a>
            <app-copy-to-clipboard copyValue="{{accessToken}}"></app-copy-to-clipboard>
          </div>
          <h3>ID Token</h3>
          <code>{{idToken}}</code>
          <div class="button-row">
            <a class="btn btn-secondary" href="https://jwt.io/?value={{idToken}}" target="_blank">Inspect at
              jwt.io</a>
            <app-copy-to-clipboard copyValue="{{idToken}}"></app-copy-to-clipboard>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
