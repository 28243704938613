<app-loading-indicator [enabled]="showLoadingIndicator" [disableInput]="disableInput">
  <div id="portal-wrapper">
    <div id="portal" class="{{pageClass}}">
      <app-leftnav></app-leftnav>
      <div id="content">
        <div class="right-content">
          <div class="top-header no-horizontal-scroll">
            <app-header></app-header>
          </div>
          <div class="main-content-wrapper">
            <div class="main-content">
              <router-outlet *ngIf="isOnline;else isOffline"></router-outlet>
              <ng-template #isOffline>
                <div class="offline">
                  <i class="fa fa-wifi fa-3x"></i>
                  <br />
                  No internet connection
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-loading-indicator>
