import { Injectable } from '@angular/core';
import { Mutex } from 'async-mutex';
import { IErrorDetails } from './error-details';

/**
 * Used to share details of the most recently occurring error between
 * ErrorService and ErrorComponent. Annoyingly you can't just do this
 * with a property on ErrorService because the instance of ErrorService
 * provided by the injector to ErrorComponent is different from the
 * instance used by Angular when an error is thrown.
 */
@Injectable({
  providedIn: 'root'
})
export class LastErrorService {

  public lastError: IErrorDetails;
  public lastErrorPendingDisplay: boolean;
  public readonly mutex = new Mutex();

}
