import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-top-filters',
  templateUrl: './page-top-filters.component.html',
  styleUrls: ['./page-top-filters.component.scss']
})
export class PageTopFiltersComponent {

  @Input() public hasExport = false;

  @Input() public containerClass = '';

  private _toggle = false;

  public get toggle(): boolean {
    return this._toggle;
  }

  @Input()
  public set toggle(value: boolean) {
    this._toggle = value;
    if (!value) { this.expanded = true; }
  }

  private _expanded = false;

  public get expanded(): boolean {
    return this._expanded || !this._toggle;
  }

  @Input()
  public set expanded(value: boolean) {
    this._expanded = value || !this._toggle;
  }

  public toggleExpanded() {
    this.expanded = !this.expanded;
  }

}
