import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-short-currency-with-tooltip',
  templateUrl: './short-currency-with-tooltip.component.html',
  styleUrls: ['./short-currency-with-tooltip.component.scss']
})
export class ShortCurrencyWithTooltipComponent {

  @Input() value: string | number;

  @Input() currencyCode: string = 'USD';

  @Input() maxDecimalPrecision = 1;

  @Input() tooltipDigitsInfo = '1.0-0';

}
