import { Pipe, PipeTransform } from '@angular/core';
import { MobileOperatingSystem } from 'src/app/api-model/enums/mobile-operating-system';

@Pipe({
  name: 'osFontIcon'
})
export class OsFontIconPipe implements PipeTransform {

  public transform(value: MobileOperatingSystem): string {
    switch (value) {
      case MobileOperatingSystem.Android:
        return 'android';
      case MobileOperatingSystem.iOS:
        return 'apple';
    }

    return '';
  }

}
