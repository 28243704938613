import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  @Input() public title: string | string[];

  @Input() public icon: string;

  @Input() public iconAlt: string;

  public get formattedTitle() {
    return Array.isArray(this.title) ? this.title.join('<i class="fa-solid fa-angle-right"></i>') : this.title;
  }

}
