import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { ShortCurrencyPipe } from './short-currency.pipe';
import { ShortNumberPipe } from './short-number.pipe';

@Pipe({
  name: 'metricValue'
})
export class MetricValuePipe implements PipeTransform {

  public constructor(
    private readonly currencyPipe: CurrencyPipe,
    private readonly shortCurrencyPipe: ShortCurrencyPipe,
    private readonly decimalPipe: DecimalPipe,
    private readonly shortNumberPipe: ShortNumberPipe,
    private readonly percentPipe: PercentPipe
  ) {}

  public transform(value: number, metric: IAttributionMetadataMetricDto, overrides?: {
    decimals?: number;
    prefix?: string;
    suffix?: string;
    expand?: true;
  }): string {
    const decimals = overrides?.decimals ?? metric.displayDecimals;
    let format = metric.displayFormat;
    if (overrides?.expand) {
      switch (format) {
        case 'short-currency':
          format = 'currency';
          break;
        case 'short-number':
          format = 'number';
          break;
      }
    }
    switch (format) {
      case 'currency':
        return this.currencyPipe.transform(value, 'USD', 'symbol', `1.${decimals}-${decimals}`);
      case 'short-currency':
        return this.shortCurrencyPipe.transform(value, 'USD', decimals);
      case 'percentage':
        return this.percentPipe.transform(value, `1.${decimals}-${decimals}`);
      case 'short-number':
        return this.shortNumberPipe.transform(value);
      case 'number':
        return `${overrides?.prefix ?? metric.displayPrefix ?? ''}${this.decimalPipe.transform(value, `1.${decimals}-${decimals}`)}${overrides?.suffix ?? metric.displaySuffix ?? ''}`;
    }
  }

}
