import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEqual, isUndefined } from 'lodash-es';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IAdvertiserDto } from '../api-model/advertiser-dto';
import { IAppNetworkDto } from '../api-model/app-network-dto';
import { IAppNetworkRegionDto } from '../api-model/app-network-region-dto';
import { INetworkDto } from '../api-model/network-dto';
import { SkipModelStateError } from '../errors/error.interceptor';
import { RegionsDataService } from '../regions/regions-data.service';
import { AuthService } from '../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppNetworksDataService {

  private _appNetworksLoading?: { [appId: string]: Promise<IAppNetworkDto[]> } = {};
  private _appNetworks$: { [appId: string]: BehaviorSubject<IAppNetworkDto[]> } = {};

  public constructor(
    private readonly http: HttpClient,
    auth: AuthService,
    regionsDataService: RegionsDataService
  ) {
    // Update whenever the user's active advertiser changes
    auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(() => this.invalidate());

    // Invalidate whenever the underlying data for spend optimisation networks or regions changes
    regionsDataService.updated$.subscribe(() => this.invalidate());
  }

  public appNetworks$(appId: string) {
    if (!this._appNetworks$[appId]) { this._appNetworks$[appId] = new BehaviorSubject<IAppNetworkDto[]>(undefined); }
    if (!this._appNetworks$[appId].value) { this.get(appId); }
    return this._appNetworks$[appId].asObservable();
  }

  public async get(appId: string, ignoreCache = false): Promise<IAppNetworkDto[]> {
    if (this._appNetworksLoading[appId]) { return this._appNetworksLoading[appId]; }
    if (!this._appNetworks$[appId]) { this._appNetworks$[appId] = new BehaviorSubject<IAppNetworkDto[]>(undefined); }
    if (!ignoreCache && !isUndefined(this._appNetworks$[appId].value)) { return this._appNetworks$[appId].value; }
    this._appNetworksLoading[appId] = new Promise(async resolve => {
      const data = (await firstValueFrom(this.http.get<INetworkDto[]>(`${environment.apiBase}apps/${appId}/networks`))) || [];
      this._appNetworks$[appId].next(data);
      resolve(data);
      this._appNetworksLoading[appId] = undefined;
    });
    return this._appNetworksLoading[appId];
  }

  public async getById(appId: string, networkId: string, ignoreCache = false): Promise<IAppNetworkDto> {
    return (await this.get(appId, ignoreCache)).find(x => x.id === networkId);
  }

  public async getRegions(appId: string, networkId: string): Promise<IAppNetworkRegionDto[]> {
    return (await this.getById(appId, networkId))?.regions;
  }

  public async getRegion(appId: string, networkId: string, regionId: string): Promise<IAppNetworkRegionDto> {
    return (await this.getById(appId, networkId))?.regions?.find(x => x.id === regionId);
  }

  public async patch(appId: string, networkConfiguration: IAppNetworkDto) {
    return firstValueFrom(this.http.patch(`${environment.apiBase}apps/${appId}/networks/${networkConfiguration.id}`, networkConfiguration, {
      // Skip model state errors because we expect the consumer to handle them
      headers: new HttpHeaders().set(SkipModelStateError, '')
    })).then((x: IAdvertiserDto) => {
      this.invalidate();
      return x;
    });
  }

  public async delete(appId: string, networkId: string): Promise<any> {
    return firstValueFrom(this.http.delete(`${environment.apiBase}apps/${appId}/networks/${networkId}`))
      .then(this.invalidate);
  }

  public async putRegion(appId: string, networkId: string, region: IAppNetworkRegionDto) {
    return firstValueFrom(this.http.put(`${environment.apiBase}apps/${appId}/networks/${networkId}/regions/${region.id}`, region, {
      // Skip model state errors because we expect the consumer to handle them
      headers: new HttpHeaders().set(SkipModelStateError, '')
    })).then((x: IAdvertiserDto) => {
      this.invalidate();
      return x;
    });
  }

  public async deleteRegion(appId: string, networkId: string, regionId: string): Promise<any> {
    return firstValueFrom(this.http.delete(`${environment.apiBase}apps/${appId}/networks/${networkId}/regions/${regionId}`))
      .then(this.invalidate);
  }

  public invalidate() {
    Object.keys(this._appNetworks$).forEach(appId => {
      // If we have no subscribers, just invalidate the observable so that the next subscription will cause the data to be re-requested - otherwise re-request the data
      if (!this._appNetworks$[appId]?.observed) { this._appNetworks$[appId]?.next(undefined); } else { this.get(appId, true); }
    });
  }

}
