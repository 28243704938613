import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  public markAllAsDirty(formGroup: FormGroup) {
    formGroup.markAllAsTouched();
    Object.values(formGroup.controls).forEach(control => {
      control.markAsDirty();
      if ((control as FormGroup).controls) { this.markAllAsDirty(control as FormGroup); }
    });
  }

}
