import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({ name: 'formatSeconds' })
export class FormatSecondsPipe implements PipeTransform {

  public transform(delta: number): string {

    if (delta === 0) {
      return '';
    }

    let isNegative = false;

    if (delta < 0) {
      delta = (delta - delta - delta);
      isNegative = true;
    }

    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    const seconds = Math.floor(delta % 60);

    let output = '';

    if (days > 0) {
      output += `${days}d `;
    }

    if (hours > 0) {
      output += `${hours}h `;
    }

    if (minutes > 0 && days <= 0) {
      output += `${minutes}m `;

    }

    if (seconds > 0 && hours <= 0 && days <= 0) {
      output += `${seconds}s `;
    }

    output = ((isNegative) ? '-' : '') + output;

    return output.trim();
  }
}
