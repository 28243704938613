import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { isDate } from 'ngx-bootstrap/chronos';

@Injectable()
@Pipe({ name: 'monthAndYear' })
export class MonthAndYearPipe implements PipeTransform {

  public transform(value: string | Date | Moment): string {
    if (value && (typeof value === 'string' || isDate(value))) { value = moment.utc(value); }
    return !moment.isMoment(value) || !value.isValid() || value.year() <= 1900 ? 'Unknown' : value.format('MMMM YYYY');
  }

}
