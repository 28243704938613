import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dollars-with-cents-tooltip',
  templateUrl: './dollars-with-cents-tooltip.component.html',
  styleUrls: ['./dollars-with-cents-tooltip.component.scss']
})
export class DollarsWithCentsTooltipComponent {

  @Input() value: string | number;

}
