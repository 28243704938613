<div class="blade-container" [ngClass]="{open: isOpen}">
  <div class="overlay" (click)="close()"></div>
  <div class="blade" [ngClass]="{wide: wide}">
    <i class="fa fa-times close-blade" (click)="close()"></i>
    <div *ngIf="title || subTitle || helpLink" class="blade-header">
      <div class="title" *ngIf="title || subTitle">
        <h2 *ngIf="title">{{title}}</h2>
        <h3 *ngIf="subTitle">{{subTitle}}</h3>
      </div>
      <a *ngIf="helpLink" class="help" [href]="helpLink" target="_blank">Help</a>
    </div>
    <div class="blade-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
