import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent implements OnInit {

  // This component is for providing an outlet for child components defined in child routing modules with no other markup.
  // It should have no markup or functionality other than to provide a <router-outlet> instance

  public constructor() { }

  public ngOnInit() {
  }

}
