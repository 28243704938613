import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentOf'
})
export class PercentOfPipe implements PipeTransform {

  public constructor(private readonly percentPipe: PercentPipe) { }

  public transform(value: number = 0, total: number = 0, digitsInfo: string = '', constrain: boolean = true): string {
    let percent = !value || !total ? 0 : value / total;
    if (constrain) { percent = Math.min(1, Math.max(0, percent)); }
    if (!percent) { percent = 0; }
    return this.percentPipe.transform(percent, digitsInfo);
  }
}
