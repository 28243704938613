import { Component, Input, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router, RouterEvent } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastOnLoadService } from './services/toast-on-load-service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {

  @Input() public height = '5px';

  @Input() public colour = '#e77a35';

  @Input() public disableInput = true;

  @Input() public enabled = true;

  @Input() public fixed = true;

  @Input() public hideContentUntilFirstRouteLoaded = true;

  public firstRouteLoading = true;

  public constructor(
    router: Router,
    public readonly loader: LoadingBarService,
    private readonly toastOnLoadService: ToastOnLoadService
  ) {
    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.firstRouteLoading = false;

        // Show delayed toasts as soon as loading placeholder is removed
        this.toastOnLoadService.initialize();
      }
    });
  }

  public ngOnInit() {}

}
