import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppIconUrlLargePipe } from './app-icon-url-large.pipe';
import { AppIconUrlSmallPipe } from './app-icon-url-small.pipe';
import { BytesPipe } from './bytes.pipe';
import { CadenceDatePipe } from './cadence-date.pipe';
import { CustomDatePipe } from './custom-date.pipe';
import { DecodeWhitespacePipe } from './decode-whitespace.pipe';
import { FirstPipe } from './first.pipe';
import { FormatSecondsPipe } from './format-seconds.pipe';
import { FormattedDatePipe } from './formatted-date.pipe';
import { IsoWeekPipe } from './iso-week.pipe';
import { JoinPipe } from './join.pipe';
import { LastPipe } from './last.pipe';
import { MetricValuePipe } from './metric-value.pipe';
import { MonthAndYearPipe } from './month-and-year.pipe';
import { MonthDateRangePipe } from './month-date-range.pipe';
import { MonthNamePipe } from './month-name.pipe';
import { NeutralPercentageChangeFromPipe } from './neutral-percentage-change-from.pipe';
import { NeutralPercentageChangePipe } from './neutral-percentage-change.pipe';
import { NeutralPercentageDifferenceFromPipe } from './neutral-percentage-difference-from.pipe';
import { NumberWordPipe } from './number-word.pipe';
import { OsFontIconPipe } from './os-font-icon.pipe';
import { OsIconPipe } from './os-icon.pipe';
import { OsNamePipe } from './os-name.pipe';
import { PercentOfPipe } from './percent-of.pipe';
import { PercentageChangeFromPipe } from './percentage-change-from.pipe';
import { PercentageChangePipe } from './percentage-change.pipe';
import { PercentageDifferenceFromPipe } from './percentage-difference-from.pipe';
import { QuarterConstrainedWeekPipe } from './quarter-constrained-week.pipe';
import { RecommendedActionPipe } from './recommended-action.pipe';
import { RoundPipe } from './round.pipe';
import { SentenceJoinPipe } from './sentence-join.pipe';
import { ShortCurrencyPipe } from './short-currency.pipe';
import { ShortDateRangePipe } from './short-date-range.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { ShortenPipe } from './shorten.pipe';
import { SubscriptionTierNamePipe } from './subscription-tier-name.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { TimespanPipe } from './timespan.pipe';
import { WeekDateRangePipe } from './week-date-range.pipe';

@NgModule({
  declarations: [
    AppIconUrlLargePipe,
    AppIconUrlSmallPipe,
    BytesPipe,
    CadenceDatePipe,
    CustomDatePipe,
    DecodeWhitespacePipe,
    FirstPipe,
    FormatSecondsPipe,
    FormattedDatePipe,
    IsoWeekPipe,
    JoinPipe,
    LastPipe,
    MetricValuePipe,
    MonthAndYearPipe,
    MonthDateRangePipe,
    MonthNamePipe,
    NeutralPercentageChangePipe,
    NeutralPercentageChangeFromPipe,
    NeutralPercentageDifferenceFromPipe,
    NumberWordPipe,
    OsFontIconPipe,
    OsIconPipe,
    OsNamePipe,
    PercentOfPipe,
    PercentageChangeFromPipe,
    PercentageChangePipe,
    PercentageDifferenceFromPipe,
    QuarterConstrainedWeekPipe,
    RecommendedActionPipe,
    RoundPipe,
    SentenceJoinPipe,
    ShortCurrencyPipe,
    ShortDateRangePipe,
    ShortNumberPipe,
    ShortenPipe,
    SubscriptionTierNamePipe,
    TimeAgoPipe,
    TimespanPipe,
    WeekDateRangePipe,
    MetricValuePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AppIconUrlLargePipe,
    AppIconUrlSmallPipe,
    BytesPipe,
    CadenceDatePipe,
    CustomDatePipe,
    DecodeWhitespacePipe,
    FirstPipe,
    FormatSecondsPipe,
    FormattedDatePipe,
    IsoWeekPipe,
    JoinPipe,
    LastPipe,
    MetricValuePipe,
    MonthAndYearPipe,
    MonthDateRangePipe,
    MonthNamePipe,
    NeutralPercentageChangePipe,
    NeutralPercentageChangeFromPipe,
    NeutralPercentageDifferenceFromPipe,
    NumberWordPipe,
    OsFontIconPipe,
    OsIconPipe,
    OsNamePipe,
    PercentOfPipe,
    PercentageChangeFromPipe,
    PercentageChangePipe,
    PercentageDifferenceFromPipe,
    QuarterConstrainedWeekPipe,
    RecommendedActionPipe,
    RoundPipe,
    SentenceJoinPipe,
    ShortCurrencyPipe,
    ShortDateRangePipe,
    ShortNumberPipe,
    ShortenPipe,
    SubscriptionTierNamePipe,
    TimeAgoPipe,
    TimespanPipe,
    WeekDateRangePipe
  ]
})
export class PipesModule {
  public static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule,
      providers: [
        AppIconUrlLargePipe,
        AppIconUrlSmallPipe,
        BytesPipe,
        CadenceDatePipe,
        CurrencyPipe,
        CustomDatePipe,
        DecimalPipe,
        DecodeWhitespacePipe,
        FirstPipe,
        FormatSecondsPipe,
        FormattedDatePipe,
        IsoWeekPipe,
        JoinPipe,
        LastPipe,
        MetricValuePipe,
        MonthAndYearPipe,
        MonthDateRangePipe,
        MonthNamePipe,
        NeutralPercentageChangePipe,
        NeutralPercentageChangeFromPipe,
        NeutralPercentageDifferenceFromPipe,
        NumberWordPipe,
        OsFontIconPipe,
        OsIconPipe,
        OsNamePipe,
        PercentPipe,
        PercentOfPipe,
        PercentageChangeFromPipe,
        PercentageChangePipe,
        PercentageDifferenceFromPipe,
        QuarterConstrainedWeekPipe,
        RecommendedActionPipe,
        RoundPipe,
        SentenceJoinPipe,
        ShortCurrencyPipe,
        ShortDateRangePipe,
        ShortNumberPipe,
        ShortenPipe,
        SubscriptionTierNamePipe,
        TimeAgoPipe,
        TimespanPipe,
        WeekDateRangePipe
      ]
    };
  }
}
