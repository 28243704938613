import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { HighchartsChartModule } from 'highcharts-angular';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { AnalysisSettingsComponent } from './analysis-settings.component';
import { AnalysisTabsComponent } from './analysis-tabs.component';
import { AppSelectorComponent } from './app-selector.component';
import { CompareDataTableComponent } from './compare-data-table.component';
import { ComparePageSettingsComponent } from './compare-page-settings.component';
import { CompareTabsComponent } from './compare-tabs.component';
import { CompareComponent } from './compare.component';
import { DrilldownTabsComponent } from './drilldown-tabs.component';
import { FilteredByComponent } from './filtered-by.component';
import { NetworkDrilldownTabsComponent } from './network-drilldown-tabs.component';
import { RawDataPageSettingsComponent } from './raw-data-page-settings.component';
import { RawDataComponent } from './raw-data.component';
import { RecentTrendsPageSettingsComponent } from './recent-trends-page-settings.component';
import { RecentTrendsComponent } from './recent-trends.component';
import { RecommendationsComponent } from './recommendations.component';

@NgModule({
  declarations: [
    AnalysisTabsComponent,
    CompareComponent,
    AppSelectorComponent,
    CompareTabsComponent,
    AnalysisSettingsComponent,
    CompareDataTableComponent,
    RecentTrendsComponent,
    RawDataComponent,
    NetworkDrilldownTabsComponent,
    DrilldownTabsComponent,
    FilteredByComponent,
    RecentTrendsPageSettingsComponent,
    ComparePageSettingsComponent,
    RawDataPageSettingsComponent,
    RecommendationsComponent
  ],
  exports: [
    AnalysisTabsComponent,
    CompareComponent,
    AppSelectorComponent,
    AnalysisSettingsComponent,
    NetworkDrilldownTabsComponent,
    CompareTabsComponent,
    DrilldownTabsComponent,
    RecentTrendsPageSettingsComponent,
    RecommendationsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ButtonsModule,
    HighchartsChartModule,
    TooltipModule,
    BsDropdownModule,
    PipesModule,
    RouterLink,
    RouterLinkActive,
    NgOptimizedImage,
    UiSwitchModule,
    ReactiveFormsModule,
    PopoverModule
  ]
})
export class AnalysisSharedModule {}
