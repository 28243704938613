import { Injectable } from '@angular/core';
import { IAdvertiserDto } from '../../api-model/advertiser-dto';
import { AuthService } from './auth.service';
import { Permissions } from './permissions';

@Injectable({
  providedIn: 'root'
})
export class AudienceService {

  public constructor(private readonly auth: AuthService) { }

  public get canManageApps() { return this.auth.profile?.activeAdvertiserId; }

  public get canManageRegions() { return this.auth?.userHasScope(Permissions.configuration); }

  public get canManageUsers() { return this.auth?.userHasScope(Permissions.users); }

  public get canAccessDeveloperApi() { return this.auth.userHasScope(Permissions.developer); }

  public get name() { return 'Advertiser'; }

  public get slug() { return this.name?.toLowerCase(); }

  public get advertiser() { return this.auth.profile.activeAdvertiser as IAdvertiserDto; }

}
