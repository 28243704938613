import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  public transform(input: string[]): string {
    return Array.isArray(input) ? input.filter(x => x).join(', ') : '';
  }

}
