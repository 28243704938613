import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import 'daterangepicker';
import daterangepicker from 'daterangepicker';
import $ from 'jquery';
import moment, { Moment } from 'moment';
import { DateRange } from './date-range';
import { FormattedDatePipe } from './pipes/formatted-date.pipe';

// noinspection TypeScriptUMDGlobal
@Component({
  selector: 'app-button-date-range',
  templateUrl: './button-date-range.component.html',
  styleUrls: ['./button-date-range.component.scss']
})
export class ButtonDateRangeComponent implements OnInit, OnDestroy {

  @Input() public dateRange: DateRange;
  @Output() public readonly dateRangeChange = new EventEmitter<DateRange>();
  @Output() public readonly changed = new EventEmitter<DateRange>();
  @Output() public readonly dateRangePresetName = new EventEmitter<string>();

  // Generate a random ID so that we can reference it from jQuery
  public id = Math.random().toString(36).substring(2);
  @Input() public namedRanges: { [name: string]: [daterangepicker.DateOrString, daterangepicker.DateOrString] } = {
    // Named ranges load dynamically 
    // Today: [moment.utc().startOf('day'), moment.utc().endOf('day')],
    // Yesterday: [moment.utc().startOf('day').subtract(1, 'days'), moment.utc().endOf('day').subtract(1, 'days')],
    // 'Last 7 Days': [moment.utc().startOf('day').subtract(6, 'days'), moment.utc().endOf('day')],
    // 'Last 30 Days': [moment.utc().startOf('day').subtract(30, 'days'), moment.utc().endOf('day')],
    // 'This Month': [moment.utc().startOf('month'), moment.utc().endOf('day')],
    // 'Last Month': [moment.utc().subtract(1, 'month').startOf('month'), moment.utc().subtract(1, 'month').endOf('month')]
  };
  private picker: JQuery<HTMLElement>;

  public constructor(private readonly formattedDate: FormattedDatePipe) { }

  private _opens: 'left' | 'right' | 'center' = 'left';
  @Input()
  public get opens() { return this._opens; }

  public set opens(value: 'left' | 'right' | 'center') {
    if (this._opens?.valueOf() === value?.valueOf()) { return; }
    this._opens = value;
    if (this._opens) { this.updatePicker(); }
  }

  private _minDate: Moment;
  @Input()
  public get minDate() { return this._minDate; }

  public set minDate(value: Moment) {
    if (this._minDate?.valueOf() === value?.valueOf()) { return; }
    this._minDate = value;
    if (this.picker) { this.updatePicker(); }
  }

  private _maxDate = moment.utc().startOf('day');
  @Input()
  public get maxDate() { return this._maxDate; }

  public set maxDate(value: Moment) {
    if (this._maxDate?.valueOf() === value?.valueOf()) { return; }
    this._maxDate = value;
    if (this.picker) { this.updatePicker(); }
  }

  private _maxSpan = { "days": 365 };
  @Input()
  public get maxSpan() { return this._maxSpan; }

  public set maxSpan(value: any) {
    if (this._maxSpan?.days === value?.days) { return; }
    this._maxSpan = value;
    if (this.picker) { this.updatePicker(); }
  }

  public get startDate() { return this.dateRange?.start; }

  public get endDate() { return this.dateRange?.end; }

  public get dateRangeName() { return (Object.entries(this.namedRanges).find(x => this.startDate.isSame(x[1][0]) && this.endDate.isSame(x[1][1])) || [])[0] || ''; }

  public get description() {
    return this.dateRangeName || `${this.formattedDate.transform(this.startDate, 'm', null, 'always')} to ${this.formattedDate.transform(this.endDate, 'm', null, 'always')}`;
  }

  public ngOnInit() {
    setTimeout(() => this.updatePicker(), 0);
  }

  public ngOnDestroy() {
    $(`#${this.id}`)?.data('daterangepicker')?.remove();
  }

  private updatePicker() {
    $(`#${this.id}`)?.data('daterangepicker')?.remove();
    this.picker = $(`#${this.id}`).daterangepicker({
      showDropdowns: true,
      alwaysShowCalendars: true,
      startDate: this.startDate,
      endDate: this.endDate,
      minDate: this.minDate,
      maxDate: this.maxDate,
      maxSpan: this.maxSpan,
      locale: { format: 'D MMM YYYY' },
      ranges: this.namedRanges,
      opens: this.opens
    } as daterangepicker.Options, (selectedStart: moment.Moment, selectedEnd: moment.Moment) => {
      this.dateRange = new DateRange(
        // Convert to UTC because daterangepicker isn't timezone aware
        moment.utc(moment.min(selectedStart, selectedEnd).format('YYYY-MM-DDTHH:mm:ss') + 'Z').startOf('day'),
        moment.utc(moment.max(selectedStart, selectedEnd).format('YYYY-MM-DDTHH:mm:ss') + 'Z').endOf('day')
      );
      this.dateRangeChange.emit(this.dateRange);
      this.changed.emit(this.dateRange);
      this.dateRangePresetName.emit(this.dateRangeName);
    });
  }

}
