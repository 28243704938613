import { Directive, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[toggleReveal]'
})
export class ToggleRevealDirective {

  private _revealed = false;

  public constructor(private el: ElementRef) {
    const parent = this.el.nativeElement.parentNode;
    const anchorEl = document.createElement('a');
    anchorEl.innerHTML = 'Show';
    anchorEl.className = 'toggle-reveal';
    anchorEl.href = 'javascript:void(0);';
    anchorEl.addEventListener('click', () => this.toggle(anchorEl));
    parent.appendChild(anchorEl);
  }

  private toggle(span: HTMLElement) {
    if (!this._revealed) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = 'Hide';
      this._revealed = true;
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = 'Show';
      this._revealed = false;
    }
  }

}
