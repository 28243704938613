import * as Highcharts from 'highcharts';

export const defaultChartOptions = {
  lang: { thousandsSep: ',' },
  title: { text: undefined },
  xAxis: {
    labels: { enabled: true },
    crosshair: {
      color: '#333',
      width: 0.5
    }
  },
  chart: {
    style: { fontFamily: `'Inconsolata', sans-serif !important` }
  },
  plotOptions: {
    spline: {
      marker: { enabled: false, symbol: 'circle' },
      lineWidth: 2
    },
    areaspline: {
      marker: { enabled: false, symbol: 'circle' }
    },
    pie: {
      borderRadius: 0
    },
    areasplinerange: {
      marker: { enabled: false, symbol: 'circle' }
    },
    column: {
      borderWidth: 0
    },
    columnrange: {
      borderWidth: 0
    },
    bar: {
      borderWidth: 0
    }
  },
  legend: {
    enabled: true,
    itemStyle: {
      fontWeight: 'normal'
    },
    itemHoverStyle: {
      fontWeight: 'normal'
    },
    itemHiddenStyle: {
      'text-decoration': 'none'
    }
  },
  tooltip: {
    useHTML: true,
    shared: true,
    backgroundColor: undefined,
    borderWidth: 0,
    shadow: false,
    followPointer: true,
    style: { padding: '0' }
  },
  credits: { enabled: false },
  exporting: { enabled: false },
  colors: [
    '#7cb5ec',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
    '#f7b2db'
  ]
} as Highcharts.Options;
