import { IGraphQLRequest } from './graphql-.request';
import { IGraphQLErrorResponse } from './graphql-error-response';

export class GraphQLResponseError extends Error {

  constructor(
    msg: string,
    public readonly errors: IGraphQLErrorResponse[],
    public readonly request: IGraphQLRequest) {
    super(msg);
    Object.setPrototypeOf(this, GraphQLResponseError.prototype);
  }

}
