import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEqual, isUndefined } from 'lodash-es';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IAppDto } from '../api-model/app-dto';
import { IHistoryDto } from '../api-model/history-dto';
import { SkipModelStateError } from '../errors/error.interceptor';
import { AuthService } from '../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppsDataService {

  private readonly _apps$ = new BehaviorSubject<IAppDto[]>(undefined);
  private appsLoading?: Promise<IAppDto[]>;

  public constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService
  ) {
    // Invalidate data when the user's active advertiser changes
    this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(() => this.invalidate());
  }

  public get apps$() {
    if (!this._apps$.value) { this.get(); }
    return this._apps$.asObservable();
  }

  public async get(ignoreCache = false): Promise<IAppDto[]> {
    if (this.appsLoading) { return this.appsLoading; }
    if (!ignoreCache && !isUndefined(this._apps$.value)) { return this._apps$.value; }
    this.appsLoading = new Promise(async resolve => {
      const data = (await firstValueFrom(this.http.get<IAppDto[]>(`${environment.apiBase}apps`))) || [];
      this._apps$.next(data);
      resolve(data);
      this.appsLoading = undefined;
    });
    return this.appsLoading;
  }

  public async getById(appId: string, ignoreCache = false) {
    const apps = await this.get(ignoreCache);
    return apps.find(x => x.id === appId);
  }

  public async create(app: IAppDto) {
    return firstValueFrom(this.http.post<IAppDto>(`${environment.apiBase}apps`, app, {
      // Skip model state errors because we expect the consumer to handle them
      headers: new HttpHeaders().set(SkipModelStateError, '')
    })).then(async (x: IAppDto) => {
      this.invalidate();
      return x;
    });
  }

  public async update(app: IAppDto) {
    return firstValueFrom(this.http.patch<IAppDto>(`${environment.apiBase}apps`, app, {
      // Skip model state errors because we expect the consumer to handle them
      headers: new HttpHeaders().set(SkipModelStateError, '')
    })).then(async (x: IAppDto) => {
      this.invalidate();
      return x;
    });
  }

  public async delete(id: string): Promise<any> {
    await firstValueFrom(this.http.delete(`${environment.apiBase}apps/${id}`));
    this.invalidate();
  }

  public async getHistory(id: string): Promise<IHistoryDto[]> {
    return firstValueFrom(this.http.get<IHistoryDto[]>(`${environment.apiBase}apps/${id}/history`));
  }

  public invalidate() {
    // If we have no subscribers, just invalidate the observable so that the next subscription will cause the data to be re-requested - otherwise re-request the data
    if (!this._apps$?.observed) { this._apps$?.next(undefined); } else { this.get(true); }
  }

}
