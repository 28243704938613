import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { isDate } from 'ngx-bootstrap/chronos';

@Pipe({
  name: 'quarterConstrainedWeek'
})
export class QuarterConstrainedWeekPipe implements PipeTransform {

  public transform(value: string | Date | Moment, includeWeekNumber = true, includeDateRange = true, separator = ': '): string {
    if (value && (typeof value === 'string' || isDate(value))) { value = moment.utc(value); }
    if (moment.isMoment(value) && value.isValid() && value.year() >= 1900) {
      const momentValue = value;
      const start = moment.max(momentValue.clone().startOf('quarter'), momentValue);
      const end = moment.min(momentValue.clone().endOf('quarter'), momentValue.clone().endOf('week').add(1, 'day'));
      const week = includeWeekNumber ? momentValue.format('[Week] W') : '';
      const dateRange = includeDateRange
        ? start.month() === end.month()
          ? `${start.format('D')}-${end.format('D MMM')}`
          : `${start.format('D MMM')}-${end.format('D MMM')}`
        : '';
      return [week, dateRange].filter(x => x).join(separator);
    }
    return '';
  }

}
