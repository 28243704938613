<app-page-header [title]="title.getTitle()"></app-page-header>
<section class="page-area">
  <div *ngIf="!profile?.anonymisedDataOnly" class="padded-content top-bottom-padding">
    <app-content-header title="We're setting up your account"></app-content-header>
    <p>
      Thanks for signing up. Our accounts team have been notified and we're reviewing your account. If you have a legitimate claim to access this application,
      we will associate your account with your organisation and notify you by email when your account is ready to use.
    </p>
    <p>
      We usually respond to new signups within a few hours, but if you have been waiting more than one business day, please
      get in touch with our support team at <a class="clickable" href="mailto:{{supportEmail}}">{{supportEmail}}</a>
    </p>
    <p *ngIf="!profile?.email_verified" class="alert alert-warning">
      <strong>IMPORTANT:</strong> To complete the signup procedure you must verify your email address. You should have received an email about this.
      Please click on the link in that email or if you can't find the email, click on the button below to resend it.
    </p>
    <p class="buttons">
      <a (click)="resendVerificationEmail()" *ngIf="!profile?.email_verified" class="btn btn-primary" href="javascript:void(0);">Resend Verification Email</a>
      <a (click)="hardReload()" class="btn btn-secondary" href="javascript:void(0);">Return to the dashboard</a>
    </p>
  </div>

  <div *ngIf="profile?.anonymisedDataOnly" class="padded-content top-bottom-padding">
    <app-content-header title="You account cannot be used on this domain"></app-content-header>
    <p>
      Your account is configured for demonstration purposes only.<br />You must access the portal at <a href="https://portal-demo.machineadvertising.com">https://portal-demo.machineadvertising.com</a>.
    </p>
    <p class="buttons">
      <a class="btn btn-secondary" href="https://portal-demo.machineadvertising.com">Go to Demo Site</a>
    </p>
  </div>
</section>
