import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ValidationMessagesService {

  public constructor(private readonly decimalPipe: DecimalPipe) {}

  public getValidationMessages(control: AbstractControl, ignorePristine = true) {

    if (!control?.errors || control.valid || (control.pristine && ignorePristine && !control.errors.server)) { return; }

    const errors: string[] = [];

    if (control.errors['required']) { errors.push('This is required'); }
    if (control.errors['min']) { errors.push(`This needs to be at least ${this.decimalPipe.transform(control.errors['min']?.min, '1.0-0')}`); }
    if (control.errors['max']) { errors.push(`This needs to be a maximum of ${this.decimalPipe.transform(control.errors['max']?.max, '1.0-0')}`); }
    if (control.errors['minlength']) { errors.push(`This needs to be at least ${control.errors['minlength']?.requiredLength} characters`); }
    if (control.errors['maxlength']) { errors.push(`This needs to be a maximum of ${control.errors['maxlength']?.requiredLength} characters`); }
    if (control.errors['pattern']) { errors.push('This field is not valid'); }
    if (control.errors['email']) { errors.push('This must be a valid email address'); }
    if (control.errors['url']) { errors.push('This must be a valid url'); }
    if (control.errors['https'] && !control.errors['url']) { errors.push('This must be a secure url (starting with https://)'); }
    if (control.errors['number']) { errors.push('This must be a positive integer'); }
    if (control.errors['appId']) { errors.push('This must be an app bundle ID e.g. id123456789 (iOS) or com.company.app-name (Android)'); }
    if (control.errors['domain']) { errors.push('This must be a valid domain in lowercase with no https:// prefix'); }
    if (control.errors['domains']) { errors.push('This must be a list of valid domains in lowercase with no https:// prefix'); }
    if (control.errors['ipAddress']) { errors.push('This must be a valid IPv4 or IPv6 address'); }
    if (control.errors['server']) { errors.push(control.errors['server']); }
    if (control.errors['custom']) { errors.push(control.errors['custom']); }

    return errors;
  }

}
