import { Injectable } from '@angular/core';
import { cloneDeep, isEqual, isUndefined, keyBy, mapValues } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { IAttributionMetadataAppDto } from '../../api-model/attribution-metadata-app-dto';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { AuthService } from '../../shared/auth/auth.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { AppsAnalysisMetadataService } from '../apps/apps-analysis-metadata.service';
import { IDashboardPageSettingsApp } from './dashboard-page-settings-app';

@Injectable({
  providedIn: 'root'
})
export class DashboardPageSettingsService {

  private availableMetricsByAppId: {
    [appId: string]: IAttributionMetadataMetricDto[];
  } = {};

  private readonly storageKey = 'pageSettings.dashboard';
  private loading?: Promise<{
    [appId: string]: IDashboardPageSettingsApp
  }>;
  private metadataByAppId: {
    [appId: string]: IAttributionMetadataAppDto
  } = {};

  public constructor(
    private readonly localStorage: LocalStorageService,
    private readonly appsAnalysisMetadataService: AppsAnalysisMetadataService,
    private readonly auth: AuthService
  ) {
    // Invalidate data when the user's active advertiser changes
    this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(() => this.invalidate());
  }

  private _settings$ = new BehaviorSubject<{
    [appId: string]: IDashboardPageSettingsApp
  }>(undefined);

  // noinspection JSUnusedGlobalSymbols
  public get settings$() {
    if (!this._settings$.value) { this.get(); }
    return this._settings$.asObservable();
  }

  public async get(ignoreCache = false) {
    if (this.loading) { return this.loading; }
    if (!ignoreCache && !isUndefined(this._settings$.value)) { return this._settings$.value; }
    this.loading = new Promise(async resolve => {
      this.metadataByAppId = keyBy((await this.appsAnalysisMetadataService.get()) || [], x => x.id);
      this.availableMetricsByAppId = mapValues(this.metadataByAppId, x => x.metrics.filter(x => x.suitableForDashboard) || []);
      const validNamesByAppId = mapValues(cloneDeep(this.availableMetricsByAppId), x => x.map(m => m.name));
      const pageSettings: {
        [appId: string]: IDashboardPageSettingsApp
      } = {};
      Object.keys(this.metadataByAppId).forEach(appId => {
        pageSettings[appId] = this.localStorage.get<IDashboardPageSettingsApp>(`${this.storageKey}.${appId}`) || {};
        pageSettings[appId].metrics = pageSettings[appId].metrics?.filter((x: string) => validNamesByAppId[appId]?.includes(x)) || [];
        if (!pageSettings[appId].metrics.length) {
          pageSettings[appId].metrics = this.availableMetricsByAppId[appId]?.filter(x => x.defaultForDashboard).map(x => x.name) || [];
        }
      });
      this._settings$.next(pageSettings);
      resolve(pageSettings);
      this.loading = undefined;
    });
    return this.loading;
  }

  public async getByAppId(appId: string) {
    const settings = await this.get();
    return settings[appId] || {};
  }

  public async getAvailableMetricsByAppId(appId: string) {
    await this.get();
    return this.availableMetricsByAppId[appId] || [];
  };

  public update(appId: string, settings: IDashboardPageSettingsApp) {
    if (isEqual(this._settings$.value, settings)) { return; }
    this.localStorage.set(`${this.storageKey}.${appId}`, settings);
    const newSettings = cloneDeep(this._settings$.value);
    newSettings[appId] = settings;
    this._settings$.next(newSettings);
  }

  public resetByAppId(appId: string) {
    this.localStorage.remove(`${this.storageKey}.${appId}`);
    const settings = cloneDeep(this._settings$.value);
    settings[appId].metrics = this.availableMetricsByAppId[appId]?.filter(x => x.defaultForDashboard).map(x => x.name) || [];
    this._settings$.next(settings);
  }

  public resetAll() {
    const appIds = Object.keys(this.metadataByAppId);
    appIds.forEach(appId => this.localStorage.remove(`${this.storageKey}.${appId}`));
    const settings = mapValues(keyBy(appIds), appId => ({
      metrics: this.availableMetricsByAppId[appId]?.filter(x => x.defaultForDashboard).map(x => x.name) || []
    } as IDashboardPageSettingsApp));
    this._settings$.next(settings);
  }

  public invalidate() {
    // If we have no subscribers, just invalidate the observable so that the next subscription will cause the data to be re-requested - otherwise re-request the data
    if (!this._settings$?.observed) { this._settings$?.next(undefined); } else { this.get(true); }
  }

}
