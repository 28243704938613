import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { isDate } from 'ngx-bootstrap/chronos';
import { AnalysisSettingsService } from '../../analysis/shared/analysis-settings.service';
import { Cadence } from '../../api-model/enums/cadence';
import { FormattedDatePipe } from './formatted-date.pipe';
import { WeekDateRangePipe } from './week-date-range.pipe';

@Injectable()
@Pipe({ name: 'cadenceDate' })
export class CadenceDatePipe implements PipeTransform {

  public constructor(
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly weekDateRangePipe: WeekDateRangePipe,
    private readonly formattedDate: FormattedDatePipe
  ) {}

  public transform(value: string | Date | Moment, short = true): string {
    if (value && (typeof value === 'string' || isDate(value))) { value = moment(value); }
    if (!moment.isMoment(value) || !value.isValid() || value.year() <= 1900) { return 'Unknown Date'; }

    const year = value.year() !== moment.utc().year() ? short ? ' YY' : ' YYYY' : '';

    switch (this.analysisSettings.cadence) {
      case Cadence.Weekly:
        return this.weekDateRangePipe.transform(value, short);
      case Cadence.Monthly:
        return (value as Moment).format(short ? `MMM${year}` : `MMMM${year}`);
      default:
        return this.formattedDate.transform(value, short ? 's' : 'dl');
    }
  }

}
