import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import Highcharts, { Options } from 'highcharts';
import { constant, difference, isEqual, keyBy, mapValues, max, merge, orderBy, times } from 'lodash-es';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppsAnalysisDataService } from './analysis/apps/apps-analysis-data.service';
import { AnalysisSettingsService } from './analysis/shared/analysis-settings.service';
import { DashboardPageSettingsService } from './analysis/shared/dashboard-page-settings.service';
import { IAppDto } from './api-model/app-dto';
import { IAppOverviewMetricsDto } from './api-model/app-overview-metrics-dto';
import { INetworkDto } from './api-model/network-dto';
import { IRegionDto } from './api-model/region-dto';
import { AppsDataService } from './apps/apps-data.service';
import { NetworksDataService } from './networks/networks-data.service';
import { RegionsDataService } from './regions/regions-data.service';
import { AuthService } from './shared/auth/auth.service';
import { ChartHelperService } from './shared/services/chart-helper.service';
import { ThemeService } from './shared/services/theme.service';

interface IAppModel {
  id: string,
  appDetails: IAppDto,
  analysis: IAppOverviewMetricsDto;
  charts: {
    networks?: Options;
    regions?: Options;
    metrics?: {
      [appId: string]: Options
    };
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnInit, OnDestroy {

  public Highcharts: typeof Highcharts = Highcharts;
  public apps: IAppModel[] = [];
  public loading = true;
  public networks: {
    [id: string]: INetworkDto
  } = {};
  public regions: {
    [id: string]: IRegionDto
  } = {};
  public unconfiguredNetworkIds: {
    app: IAppDto;
    networkIds: string[];
  }[] = [];
  public metricColumnCount = 0;
  public showNetworksBreakdown = true;
  public showRegionsBreakdown = true;

  private analysis: IAppOverviewMetricsDto[];
  private activeAdvertiserSubscription: Subscription;
  private routeDataSubscription?: Subscription;
  private pageSettingsSubscription?: Subscription;
  private appsSubscription?: Subscription;
  private networksSubscription?: Subscription;
  private regionsSubscription?: Subscription;
  private settingsSubscription: Subscription;
  private themeSubscription?: Subscription;

  public constructor(
    public readonly title: Title,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly pageSettingsService: DashboardPageSettingsService,
    private readonly appsDataService: AppsDataService,
    private readonly appsAnalysisDataService: AppsAnalysisDataService,
    private readonly networksDataService: NetworksDataService,
    private readonly regionsDataService: RegionsDataService,
    private readonly chartHelper: ChartHelperService,
    private readonly analysisSettings: AnalysisSettingsService,
    private readonly theme: ThemeService,
    private readonly currencyPipe: CurrencyPipe
  ) {
    this.title.setTitle(`${environment.companyName}: ${environment.productName}`);
  }

  public get supportEmail() { return environment.supportEmail; }

  public ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(d => {
      this.analysis = d.analysis;
      this.loadData();
    });

    this.activeAdvertiserSubscription = this.auth.activeAdvertiser$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => this.loadData(true));
    this.pageSettingsSubscription = this.pageSettingsService.settings$.pipe(skip(1), distinctUntilChanged(isEqual)).subscribe(async () => this.loadData(true));
    this.appsSubscription = this.appsDataService.apps$.subscribe(_ => this.composeAppsAndConfigureCharts());

    this.networksSubscription = this.networksDataService.networks$.subscribe(x => {
      this.networks = keyBy(x, x => x.id) || {};
      this.composeAppsAndConfigureCharts();
    });

    this.regionsSubscription = this.regionsDataService.regions$.subscribe(x => {
      this.regions = keyBy(x, x => x.id) || {};
      this.composeAppsAndConfigureCharts();
    });

    this.settingsSubscription?.unsubscribe();
    this.analysisSettings.settings$.pipe(skip(1), distinctUntilChanged((x, y) => 
      isEqual(x.cadence, y.cadence) && 
      isEqual(x.smoothingPeriod, y.smoothingPeriod) &&
      isEqual(x.dateRange, y.dateRange)))
      .subscribe(async () => this.loadData(true));

    this.themeSubscription = this.theme.changed$.subscribe(_ => this.configureCharts());
  }

  public ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.activeAdvertiserSubscription?.unsubscribe();
    this.pageSettingsSubscription?.unsubscribe();
    this.appsSubscription?.unsubscribe();
    this.networksSubscription?.unsubscribe();
    this.regionsSubscription?.unsubscribe();
    this.settingsSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }

  public async loadData(reloadAnalysis = false) {
    this.loading = true;
    const settings = await this.pageSettingsService.get();
    if (reloadAnalysis) {
      this.analysis = await this.appsAnalysisDataService.get(mapValues(settings, x => x.metrics));
    }
    this.composeAppsAndConfigureCharts();
  }

  public paddingColumns(app: IAppModel) {
    return times(Math.max(this.metricColumnCount - (app.analysis?.metrics.length || 0), 0), constant(null));
  }

  private async composeAppsAndConfigureCharts() {
    const settings = await this.pageSettingsService.get();
    this.apps = [];
    const apps = await this.appsDataService.get();
    this.apps = orderBy(this.analysis?.map(x => ({
      id: x.appId,
      appDetails: apps.find(a => a.id === x.appId),
      analysis: (() => {
        x.metrics = orderBy(x.metrics.filter(m => settings[x.appId]?.metrics.includes(m.config.name)), m => settings[x.appId]?.metrics.indexOf(m.config.name));
        return x;
      })(),
      charts: {
        metrics: {}
      }
    } as IAppModel), (x: IAppModel) => x.appDetails?.displayName));

    this.metricColumnCount = max(this.apps.map(x => x.analysis.metrics.length));
    this.showNetworksBreakdown = this.apps.some(app => Object.keys(app.analysis.spendBreakdown?.byNetwork).length > 1);
    this.showRegionsBreakdown = this.apps.some(app => Object.keys(app.analysis.spendBreakdown?.byRegion).length > 1);

    this.unconfiguredNetworkIds = this.apps?.map(app => ({
      app: app.appDetails,
      networkIds: difference(Object.keys(app.analysis.spendBreakdown?.byNetwork || {}), Object.keys(this.networks))
    })).filter(x => x.networkIds.length);
    this.configureCharts();
    this.loading = false;
  }

  private configureCharts() {
    // tslint:disable-next-line: no-this-assignment
    const self = this;

    // Clear the existing charts before we start
    this.apps.forEach(x => x.charts = { metrics: {} });

    for (const app of this.apps.filter(x => x.analysis)) {
      const allocationByNetworkId = app.analysis.spendBreakdown?.byNetwork ?? {};
      const allocationByRegionId = app.analysis.spendBreakdown?.byRegion ?? {};

      app.charts = {
        networks: merge(this.chartHelper.inlineDonutChart(x => self.currencyPipe.transform(x, 'USD', 'symbol', '1.0-0')), {
          title: {
            useHTML: true,
            text: `<div class="inline-donut-headline"><strong>${Object.keys(allocationByNetworkId).length}</strong><span>Network${Object.keys(allocationByNetworkId).length === 1 ? '' : 's'}</span></div>`
          },
          series: [{
            name: 'Networks',
            data: Object.entries(allocationByNetworkId).map(([networkId, spend]) => {
              const network = this.networks[networkId];
              return ({
                y: spend,
                name: network?.displayName || 'Unconfigured Network',
                id: network?.id,
                iconUrl: network?.iconUrlSmall || '/assets/images/default-network-icon.svg'
              });
            })
          }]
        } as Options),
        regions: merge(this.chartHelper.inlineDonutChart(x => self.currencyPipe.transform(x, 'USD', 'symbol', '1.0-0')), {
          title: { text: `<div class="inline-donut-headline"><strong>${Object.keys(allocationByRegionId).length}</strong><span>Region${Object.keys(allocationByRegionId).length === 1 ? '' : 's'}</span></div>` },
          series: [{
            name: 'Regions',
            data: Object.entries(allocationByRegionId).map(([regionId, spend]) => {
              const region = this.regions[regionId];
              return ({
                y: spend,
                name: region?.displayName || 'Unconfigured Region',
                iconUrl: region?.iconUrl || '/assets/images/default-region-icon.svg'
              });
            })
          }]
        } as Options),
        metrics: {}
      };

      // Metric charts
      for (let metric of app.analysis?.metrics || []) {
        app.charts.metrics[metric.config.name] = this.chartHelper.miniTrendLine({ metric: metric.config, data: metric?.trend.filter(x => x.hasAttributionData) });
      }

    }
  }
}
