// noinspection JSUnusedGlobalSymbols

import moment from 'moment';
import { IDateRangeDto } from '../api-model/date-range-dto';
import { Cadence } from '../api-model/enums/cadence';

const DEFAULT_DAYS = 31;
const DEFAULT_WEEKS = 12;
const DEFAULT_MONTHS = 12;

const MAX_DAYS = 31;
const MAX_WEEKS = 12;
const MAX_MONTHS = 12;

const MIN_DAYS = 1;
const MIN_WEEKS = 1;
const MIN_MONTHS = 1;

export class DateRange {

  private static readonly WeeksNamedRange = this.getWeeksNamedRange()
  private static readonly MonthsNamedRange = this.getMonthsNamedRange()
  private static readonly DaysNamedRange = this.getDaysNamedRange()

  public constructor(public start: moment.Moment, public end: moment.Moment, public name = '') { }

  public static get default(): DateRange {
    return moment.utc().date() >= 2
      ? new DateRange(moment.utc().startOf('month').startOf('day'), moment.utc().endOf('day'))
      : new DateRange(moment.utc().startOf('month').startOf('day').subtract(1, 'month'), moment.utc().startOf('month').subtract(1, 'day').endOf('day'));
  }

  public get days() { return this.end.clone().startOf('day').add(1, 'day').diff(this.start, 'days'); }

  public static fromString(value: string): DateRange | null {
    const parts = (value || '').split('|');
    if (parts.length !== 2) { return null; }
    return new DateRange(moment.utc(parts[0]), moment.utc(parts[1]));
  }

  public static fromDateRangeDto(dto: IDateRangeDto) {
    return !dto?.start || !dto?.end ? null : new DateRange(moment.utc(dto.start), moment.utc(dto.end));
  }

  public toString() {
    return `${this.start.toISOString()}|${this.end.toISOString()}`;
  }


  public static toDateRangeDto(value: DateRange): IDateRangeDto | null {
    return { start: value.start.toDate(), end: value.end.toDate() };
  }

  public static getDaysNamedRange() {
    const ranges = {};
    const [r1, r2, r3] = [7, 14, MAX_DAYS]

    let range = DateRange.getRecentDaysRange(r3);
    ranges[`Last ${r3} days`] = [moment.utc(range.start), moment.utc(range.end)]

    range = DateRange.getRecentDaysRange(r2);
    ranges[`Last ${r2} days`] = [moment.utc(range.start), moment.utc(range.end)]

    range = DateRange.getRecentDaysRange(r1);
    ranges[`Last ${r1} days`] = [moment.utc(range.start), moment.utc(range.end)]

    return ranges;
  }

  public static getWeeksNamedRange() {
    const ranges = {};
    const [r1, r2, r3] = [4, 8, MAX_WEEKS]

    let range = DateRange.getRecentWeeksRange(r3);
    ranges[`Last ${r3} weeks`] = [moment.utc(range.start), moment.utc(range.end)]

    range = DateRange.getRecentWeeksRange(r2);
    ranges[`Last ${r2} weeks`] = [moment.utc(range.start), moment.utc(range.end)]

    range = DateRange.getRecentWeeksRange(r1);
    ranges[`Last ${r1} weeks`] = [moment.utc(range.start), moment.utc(range.end)]

    return ranges;
  }

  public static getMonthsNamedRange() {
    const ranges = {};
    const [r1, r2, r3] = [6, 9, MAX_MONTHS]

    let range = DateRange.getRecentMonthsRange(r3);
    ranges[`Last ${r3} months`] = [moment.utc(range.start), moment.utc(range.end)]

    range = DateRange.getRecentMonthsRange(r2);
    ranges[`Last ${r2} months`] = [moment.utc(range.start), moment.utc(range.end)]

    range = DateRange.getRecentMonthsRange(r1);
    ranges[`Last ${r1} months`] = [moment.utc(range.start), moment.utc(range.end)]

    return ranges;
  }

  public static getNamedDateRanges(cadence: Cadence) {
    switch (cadence) {
      case Cadence.Weekly:
        return this.WeeksNamedRange;
      case Cadence.Monthly:
        return this.MonthsNamedRange;
      default:
        return this.DaysNamedRange;
    }
  }

  public static getDefaultDateRange(cadence: Cadence): DateRange {
    switch (cadence) {
      case Cadence.Weekly:
        return DateRange.fromDateRangeDto(DateRange.getRecentWeeksRange(DEFAULT_WEEKS));
      case Cadence.Monthly:
        return DateRange.fromDateRangeDto(DateRange.getRecentMonthsRange(DEFAULT_MONTHS));
      default:
        return DateRange.fromDateRangeDto(DateRange.getRecentDaysRange(DEFAULT_DAYS));
    }
  }

  public static getRecentDaysRange(n: number): IDateRangeDto {
    return {
      start: moment.utc().subtract(n, 'days').startOf('day').toDate(),
      end: moment.utc().endOf('day').toDate()
    };
  }

  public static getRecentWeeksRange(n: number): IDateRangeDto {
    return {
      start: moment.utc().startOf('isoWeek').subtract(n, 'weeks').toDate(),
      end: moment.utc().startOf('isoWeek').subtract(1, 'day').endOf('day').toDate()
    };
  }

  public static getRecentMonthsRange(n: number): IDateRangeDto {
    return {
      start: moment.utc().startOf('month').subtract(n, 'months').toDate(),
      end: moment.utc().startOf('month').subtract(1, 'day').endOf('day').toDate()
    };
  }

  public static getComputedWeeksRange(dateRange: DateRange): DateRange {
    const isEndDtInCurrent = dateRange.end.isSame(new Date(), 'week');
    if (isEndDtInCurrent) {
      return new DateRange(
        dateRange.start.clone().startOf('isoWeek'),
        moment.utc().endOf('day')
      );
    } else {
      return new DateRange(
        dateRange.start.clone().startOf('isoWeek'),
        dateRange.end.clone().endOf('isoWeek').endOf('day')
      );
    }
  }

  public static getComputedMonthsRange(dateRange: DateRange): DateRange {
    const isEndDtInCurrent = dateRange.end.isSame(new Date(), 'month');
    if (isEndDtInCurrent) {
      return new DateRange(
        dateRange.start.clone().startOf('month'),
        moment.utc().endOf('day')
      );
    } else {
      return new DateRange(
        dateRange.start.clone().startOf('month'),
        dateRange.end.clone().endOf('month').endOf('day')
      );
    }
  }

  public static getComputedDaysRange(dateRange: DateRange): DateRange {
    return new DateRange(
      dateRange.start.clone().startOf('day'),
      dateRange.end.clone().endOf('day')
    );
  }

  public static getMinimumWeeksRange(dateRange: DateRange): DateRange {
    return new DateRange(
      dateRange.end.clone().startOf('isoWeek').subtract(MIN_WEEKS, 'weeks'),
      dateRange.end.clone().add(1, 'day').startOf('isoWeek').subtract(1, 'day').endOf('day')
    );
  }

  public static getMinimumMonthsRange(dateRange: DateRange): DateRange {
    return new DateRange(
      dateRange.end.clone().startOf('month').subtract(MIN_MONTHS, 'months'),
      dateRange.end.clone().add(1, 'day').startOf('month').subtract(1, 'day').endOf('day')
    );
  }

  public static getMinimumDaysRange(dateRange: DateRange): DateRange {
    return new DateRange(
      dateRange.end.clone().subtract(MIN_DAYS, 'days').startOf('day'),
      dateRange.end
    );
  }

  public static getValidCustomDateRange(cadence: Cadence, currentDateRange: DateRange): DateRange {
    switch (cadence) {
      case Cadence.Weekly:
        {
          const diff = DateRange.dateDiffInWeeks(currentDateRange);
          if (diff >= MIN_WEEKS - 1 && diff <= MAX_WEEKS - 1)
            return DateRange.getComputedWeeksRange(currentDateRange);
          return DateRange.getMinimumWeeksRange(currentDateRange);
        }
      case Cadence.Monthly:
        {
          const diff = DateRange.dateDiffInMonths(currentDateRange);
          if (diff >= MIN_MONTHS - 1 && diff <= MAX_MONTHS - 1)
            return DateRange.getComputedMonthsRange(currentDateRange);
          return DateRange.getMinimumMonthsRange(currentDateRange);
        }
      default:
        {
          const diff = DateRange.dateDiffInDays(currentDateRange);
          if (diff >= MIN_DAYS - 1 && diff <= MAX_DAYS)
            return DateRange.getComputedDaysRange(currentDateRange);
          return DateRange.getMinimumDaysRange(currentDateRange);
        }
    }
  }

  public static dateDiffInWeeks(dateRange: DateRange): number {
    return dateRange.end.diff(dateRange.start, 'weeks');
  }

  public static dateDiffInMonths(dateRange: DateRange): number {
    return dateRange.end.diff(dateRange.start, 'months');

  }

  public static dateDiffInDays(dateRange: DateRange): number {
    return dateRange.end.diff(dateRange.start, 'days');
  }

  public static getMinDate(cadence: Cadence) {
    switch (cadence) {
      case Cadence.Weekly:
        return moment.utc().startOf('isoWeek').subtract(52 * 3, 'weeks');
      case Cadence.Monthly:
        return moment.utc().startOf('month').subtract(12 * 3, 'months');
      default:
        return moment.utc().subtract(365 * 3, 'days').startOf('day');
    }
  }

  public static getMaxSpan(cadence: Cadence) {
    switch (cadence) {
      case Cadence.Weekly:
        return { "days": 84 };
      case Cadence.Monthly:
        return { "days": 365 };
      default:
        return { "days": 31 };
    }
  }
}
